import { User } from 'src/app/_models/user';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService, AlertService, ReciboService } from 'src/app/_services';
import { Location } from '@angular/common';

@Component({
  selector: 'app-extratodetalhe',
  templateUrl: './extratodetalhe.component.html',
  styleUrls: ['./extratodetalhe.component.less']
})
export class ExtratodetalheComponent implements OnInit {

  currentUserTC: User;
  id: number;
  forn: any = [];
  botao: string = 'Carregando...';

  constructor(
    private router: ActivatedRoute,
    private reciboService: ReciboService,
    private authenticationService: AuthenticationService,
    public _location: Location,
    private alertService: AlertService
  ) {
    this.currentUserTC = this.authenticationService.currentUserTCValue;
    //console.log(this.currentUserTC);
  }

  validForm() {
    if (!this.forn['maqplan_fornecedor_id'])
      return false
    else
      this.botao = 'Voltar';
    return true;
  }

  enviarExtrato() {
    this.alertService.clear();
    const formData = new FormData();
    formData.append('id', this.forn[0].fornecedor_id);
    this.reciboService.extratoEmail(formData).subscribe(
      (res) => {
        this.alertService.success(res.message);
      },
      (err) => {
        //console.log(err);
        this.alertService.error(err.error.message);
      }
    )
  }

  enviarRecibo(id) {
    this.alertService.clear();
    const formData = new FormData();
    formData.append('id', this.forn[0].fornecedor_id);
    formData.append('recibo_id', id);
    this.reciboService.reciboEmail(formData).subscribe(
      (res) => {
        this.alertService.success(res.message);
      },
      (err) => {
        //console.log(err);
        this.alertService.error(err.error.message);
      }
    )
  }

  ngOnInit() {
    this.id = this.router.snapshot.params.id;
    this.reciboService.extrato(this.id)
      .subscribe(f => {
        this.forn = f.data;
        if(f.data.qtd_notas_devolucao>0) this.alertService.success('Existem notas para devolução (' + f.data.qtd_notas_devolucao + ')');
      },
        (err) => this.alertService.error(err.error.message));
  }

}
