import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RelatorioService {
  constructor(private http: HttpClient) { }

  relatorioSaque(id_loja: number, dataini: string, datafim: string): Observable<any> {
    const params = new HttpParams().set('dataini', dataini || '').set('datafim', datafim || '');
    return this.http.get(`${environment.apiUrl}/report/recibos/${id_loja}`, { params });
  }

}
