import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-documento',
  templateUrl: './documento.component.html',
  styleUrls: ['./documento.component.less']
})
export class DocumentoComponent implements OnInit {
  idContrato: string;
  selectedFile = null;
  imagem1;
  imagem2;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.idContrato = this.route.snapshot.params.contrato;
  }

  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
  }

  onUpload(img) {
    //console.log(JSON.stringify(this.selectedFile)); // You can use FormData upload to backend server
    if (this.selectedFile) {
      let reader = new FileReader();
      reader.readAsDataURL(this.selectedFile);
      reader.onload = () => {
        if(img==1)
          this.imagem1 = reader.result;
        else
          this.imagem2 = reader.result;
      };
    }
  }

  salvarImagens() {
    localStorage.setItem(this.idContrato + 'imagem1', JSON.stringify(this.imagem1));
    localStorage.setItem(this.idContrato + 'imagem2', JSON.stringify(this.imagem2));
    //console.log(localStorage.getItem(this.idContrato + 'imagem1'));
    this.router.navigate(['/contrato']);
  }

}
