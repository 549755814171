import { Component, OnInit } from '@angular/core';
import { UserService, AuthenticationService, AlertService } from '../_services';
import { User } from '../_models/user';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.less']
})
export class ProfileComponent implements OnInit {

  currentUserTC: User;
  usuario = [];
  points = [];
  signatureImage;
  assinado: boolean = false;
  assinar: boolean = false;
  loading: boolean = false;
  fotoUpload: File = null;

  formPerfil = new FormGroup({
    email: new FormControl(''),
    senha: new FormControl(''),
    senha2: new FormControl(''),
    foto: new FormControl('')
  });

  constructor(
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private alertService: AlertService
  ) {
    this.currentUserTC = this.authenticationService.currentUserTCValue;
  }

  showImage(data) {
    this.signatureImage = data;
    this.assinar = false;
    this.assinado = true;
  }

  fileInput(files: FileList) {
    console.log('fileInput');
    this.fotoUpload = files.item(0);
  }

  submitForm() {
    this.loading = true
    this.alertService.clear();
    this.assinado = false;
    const formData = new FormData();
    formData.append('user', this.currentUserTC.id.toString());
    formData.append('email', this.formPerfil.get('email').value);
    formData.append('password', this.formPerfil.get('senha').value);
    formData.append('password_confirmation', this.formPerfil.get('senha2').value);
    if (this.fotoUpload) formData.append('uploadProfileImg', this.fotoUpload, this.fotoUpload.name);
    formData.append('uploadProfileSign', this.signatureImage || '');
    this.userService.salvarPerfil(formData).subscribe(
      (res) => {
        //console.log(res);
        this.currentUserTC.profile_sign_url = this.signatureImage;
        localStorage.setItem('currentUserTC', JSON.stringify(this.currentUserTC));
        this.loading = false;
        this.alertService.success(res.message);
      },
      (err) => {
        //console.log(err);
        this.loading = false;
        this.alertService.error(err.error.message);
      })
  }

  ngOnInit() {
    this.userService.perfil(this.currentUserTC.loja_id)
      .subscribe(u => {
        this.usuario = u['msg'];
        this.formPerfil.get("email").setValue(this.usuario['email']);
      },
        (err) => this.alertService.error(err.error.message));
  }

}
