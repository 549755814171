<div class="container" *ngIf="pdf" align="center">
  <a href="{{ pdf }}" target="_blank"
    >Contrato <i class="far fa-file-alt"></i
  ></a>
</div>
<div class="container" *ngIf="!pdf">
  <div class="mb-5" align="justify" *ngIf="!erroCont">
    <style>
      .header_data {
        border-radius: 25px;
        border: 2px solid gray;
        margin-bottom: 4px;
        padding-bottom: 10px;
      }
    </style>
    <div class="row">
      <div
        class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 header_data"
      >
        <h2>CONSIGNANTE:</h2>
        <b>Código do contrato:</b> {{ cont.fornecedor_contrato }} <br />
        <b>Nome do Consignante:</b> {{ cont.fornecedor_razao_social }} <br />
        <b>CPF:</b> {{ cont.fornecedor_cpf_cnpj | CPFCNPJ }}<br />
        <b>Telefones:</b> {{ cont.fornecedor_telefone }}
        {{ cont.fornecedor_celular }} <br />
        <b>E-mail:</b> {{ cont.fornecedor_email }} <br />
        <b>Representante:</b> {{ cont.fornecedor_representante }} <br />
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <p class="texto mt-3">
          <b>CONSIGNATÁRIA:</b> {{ currentUserTC?.loja.nome_fantasia }},
          sociedade por ações de capital fechado inscrita no, CNPJ
          {{ currentUserTC.loja.cpf_cnpj }}, situada na
          {{ currentUserTC.loja.uf_name }},
          {{ currentUserTC.loja.end_completo }}, neste ato representada nos
          termos de seu estatuto social. ou Qualificação específica da pessoa
          jurídica, no caso de FRANQUEADO.
        </p>
        <p class="texto">
          O(A) CONSIGNANTE e a CONSIGNATÁRIA, acima qualificados, celebram,
          entre si, o presente instrumento particular denominado CONTRATO DE
          CONSIGNAÇÃO, que será regido pela legislação aplicável, em especial,
          pelos artigos 534 e seguintes do Código Civil e pelas cláusulas e
          disposições seguintes:
        </p>
        <p class="texto">
          Cláusula 1ª – Considera-se CONSIGNANTE a pessoa física ou jurídica
          que, voluntariamente e com o objetivo de vender bens móveis de sua
          propriedade, deixa-os sob os cuidados da CONSIGNATÁRIA, a fim de que
          esta proceda à comercialização respectiva, com a posterior divisão com
          o CONSIGNANTE dos recursos líquidos resultantes da venda.
        </p>
        <p class="texto">
          Cláusula 2ª – A partir da assinatura do presente contrato, fica o(a)
          CONSIGNANTE habilitado a disponibilizar à CONSIGNATÁRIA bens móveis de
          sua propriedade e de cuja origem e autenticidade se responsabiliza
          para fins civis e penais, a fim de que esta os exponha à venda, após
          regular avaliação.<br />
          §1º. Os bens deixados em consignação serão conferidos, cadastrados,
          avaliados e lançados no sistema informatizado em nome do(a)
          CONSIGNANTE.<br />
          §2º. A avaliação citada acima será realizada com exclusividade pela
          CONSIGNATÁRIA, com base no preço médio que pratica e considerando o
          estado de conservação do produto, sua marca, sua liquidez, sua
          facilidade de comercialização e outros demais aspectos que a
          CONSIGNATÁRIA entender, ao seu juízo, pertinentes à venda do produto,
          não cabendo ao(à) CONSIGNANTE interferir nessa avaliação, a qual
          declara expressamente anuir por força do presente, para nada
          questionar a esse respeito.<br />
          §3º. A CONSIGNATÁRIA poderá recusar, sem necessidade de apresentar
          qualquer justificativa, a recepção de bens deteriorados, desgastados
          em excesso ou que, por qualquer outro motivo, considere não vendáveis
          e/ou compatíveis com a qualidade mínima dos itens que habitualmente
          comercializa.<br />
          §4º. As peças reprovadas na triagem mencionada acima serão devolvidas
          ao(à) CONSIGNANTE, que, após receber o aviso de recusa da
          CONSIGNATÁRIA, terá 07 (sete) dias úteis para retirá-las da loja. Após
          este prazo, o(a) CONSIGNANTE autoriza a CONSIGNATÁRIA a doar os bens
          para entidades assistenciais ou instituições filantrópicas de
          reconhecida idoneidade e lisura.<br />
          §5º. O(A) CONSIGNANTE, desde já, autoriza que a doação prevista no
          parágrafo anterior poderá ser realizada pela CONSIGNATÁRIA em favor do
          Instituto Eu Sou Peça Rara (38.571.326/0001-41), para que este promova
          a venda do produto por valores simbólicos e para fins sociais.
        </p>
        <p class="texto">
          Cláusula 3ª – A CONSIGNATÁRIA se responsabiliza pela guarda dos bens
          deixados em consignação, assumindo a responsabilidade por eventuais
          danos, excetuadas situações de caso fortuito ou de força maior, tais
          como fenômenos da natureza, calamidades, incêndios, catástrofes,
          saques, roubos, além de outros eventos imprevisíveis, aos quais não
          tenha dado causa.
        </p>
        <p class="texto">
          Cláusula 4ª – Os bens consignados serão expostos à venda de acordo com
          a organização interna e sob o exclusivo critério da CONSIGNATÁRIA, não
          cabendo ao(à) CONSIGNANTE interferir no processo de exposição, venda
          física ou pela internet (e-commerce), anúncios, inclusive em mídias
          digitais, ou qualquer outro mecanismo de comercialização do produto.
        </p>
        <p class="texto">
          Cláusula 5ª – Realizada a venda do bem consignado, caberá ao(à)
          CONSIGNANTE o montante correspondente a 50% (cinquenta por cento) do
          valor líquido apurado, este compreendido como sendo o resultado da
          operação depois de deduzidos custos com as administradoras de cartão,
          tributos, taxas, encargos e demais despesas consequentes do processo
          de comercialização.<br />
          §1º. O valor líquido devido ao(à) CONSIGNANTE estará disponível para
          retirada no mês subsequente à comercialização, devendo este(a) ou seu
          representante legal comparecer à sede da CONSIGNATÁRIA para requerer o
          recurso.<br />
          §2º. O pagamento ao(à) CONSIGNANTE poderá ser feito em espécie, para
          valores inferiores a R$ 100,00 (cem reais), transferência bancária,
          bem como poderá permanecer registrado no cadastro do(a) CONSIGNANTE,
          até que este(a) decida a melhor destinação.<br />
          §3º. Optando o(a) CONSIGNANTE pela transferência bancária dos
          recursos, do valor que lhe é devido serão abatidos R$2,00 (dois reais)
          pelos custos da operação (PIX/DOC/TED) e o crédito em conta deverá ser
          realizado em até 7 (sete) dias úteis, contados a partir da
          solicitação.<br />
          §4º. Caberá ao(à) CONSIGNANTE acompanhar se e quando o produto foi
          vendido, podendo este(a) valer-se de informações constantes nos totens
          localizados nas lojas da CONSIGNATÁRIA.<br />
          §5º. Aplica-se ao presente contrato o de prescrição trienal previsto
          no artigo 206, §3º, inciso IV do Código Civil.
        </p>
        <p class="texto">
          Cláusula 6ª – Fica, desde já, ressalvado o direito da CONSIGNATÁRIA, a
          qualquer tempo, devolver o produto ao(à) CONSIGNANTE, mediante prévia
          comunicação por e-mail e/ou aplicativo próprio.<br />
          Parágrafo Único. Transcorrido o prazo de 30 (trinta) dias a partir da
          comunicação prevista no caput e não retirado o bem pelo CONSIGNANTE,
          fica a CONSIGNATÁRIA autorizada a doar o bem para as entidades citadas
          nos parágrafos 4º e 5º da Cláusula 2ª.
        </p>
        <p
          class="texto"
          [ngStyle]="{
            'text-decoration': option_reducao ? '' : 'line-through'
          }"
        >
          Cláusula 7ª – Caso o bem consignado não seja comercializado em até 90
          (noventa) dias contados do seu cadastramento, o(a) CONSIGNANTE
          autoriza a CONSIGNATÁRIA a reduzir em até 50% (cinquenta por cento) o
          valor do(s) produto(s) não vendido(s), com o objetivo de aumentar a
          liquidez do bem e evitar o processo de devolução.
        </p>
        <p class="texto">
          Cláusula 8ª - Caso o(a) CONSIGNANTE queira retirar a (s) peça (s)
          consignada da loja, deverá informar a CONSIGNATÁRIA por escrito com um
          prazo mínimo de trinta dias de antecedência.<br />
          §1º. Na hipótese da CONSIGNATÁRIA, por qualquer motivo, não localizar
          a(s) peça(s) solicitada (s) pelo CONSIGNANTE, este(a) receberá o valor
          correspondente ao que teria direito caso a venda houvesse sido
          realizada.<br />
          §2º. Fica terminantemente proibido ao(à) CONSIGNANTE procurar o bem
          consignado no interior da loja, tanto nos espaços de exposição dos
          produtos, como nas áreas restritas do estabelecimento, sob pena de
          rescisão imediata do presente contrato.<br />
          §3º. Na hipótese de o bem requerido de volta pelo CONSIGNANTE já ter
          sido comercializado, a este competirá tão somente o valor previsto na
          cláusula 5ª.
        </p>
        <p class="texto">
          Cláusula 9ª - Este instrumento poderá ser rescindido unilateralmente
          pela CONSIGNATÁRIA na hipótese de interesse público ou de violação das
          cláusulas contratuais pelo(a) CONSIGNANTE, ou, ainda, por mútuo
          consentimento, independentemente de notificação judicial ou extra
          judicial.
        </p>
        <p class="texto">
          Cláusula 10ª - O CONSIGNANTE reconhece e está ciente de que o presente
          instrumento não caracteriza um contrato de adesão e que o ajuste aqui
          firmado não se trata de uma relação de consumo, sendo, portanto, o
          presente contrato de consignação (estimatório) de natureza paritária e
          regido pela Lei 10.406/2002.
        </p>
        <p class="texto">
          Cláusula 11ª - O CONSIGNANTE, em observância à Lei nº. 13.709/18 - Lei
          Geral de Proteção de Dados Pessoais - e demais normativas aplicáveis à
          hipótese, autoriza, livre, expressa e consciente, A CONSIGNATÁRIA a
          realizar o tratamento de seus Dados Pessoais para as finalidades deste
          contrato e de acordo com as condições estabelecidas no termo de
          especificação e tratamento de dados, disponível em PEÇA RARA - LGPD:
          §1º. A referida autorização poderá, a qualquer tempo, ser revogada por
          escrito pelo CONSIGNANTE. §2º. A CONSIGNATÁRIA fica autorizada a
          compartilhar os dados pessoais do Titular com outros agentes de
          tratamento de dados, caso seja necessário para as finalidades listadas
          neste instrumento, desde que, sejam respeitados os princípios da
          boa-fé, finalidade, adequação, necessidade, livre acesso, qualidade
          dos dados, transparência, segurança, prevenção, não discriminação e
          responsabilização e prestação de contas.
        </p>
        <p class="texto">
          Cláusula 12ª -A CONSIGNATÁRIA se compromete a tratar os Dados Pessoais
          do CONSIGNANTE de forma sigilosa e confidencial, mantendo-os em
          ambiente seguro e não sendo utilizados para qualquer fim que extrapole
          o objeto do presente contrato.
        </p>
        <p class="texto">
          Cláusula 13ª - Fica eleito o foro da Circunscrição Especial Judiciária
          de Brasília-DF para dirimir quaisquer dúvidas ou litígios decorrentes
          deste instrumento, renunciando a qualquer outro, por mais privilegiado
          que seja.
        </p>
        <p>&nbsp;</p>
        <p class="texto">
          E por estarem assim ajustadas e contratadas, as partes, no pleno uso
          de sua capacidade, com total discernimento e expressando livremente
          sua vontade, declaram expressamente que tomaram ciência do inteiro
          teor do contrato e firmam o presente instrumento em duas vias de
          idêntico teor e forma, contendo 03 (três) folhas cada.
        </p>
        <p class="texto">
          <span style="text-transform: capitalize">{{
            cont?.loja.loja_end_cidade.toLowerCase()
          }}</span
          >, {{ dataAtual }}.
        </p>
        <p>&nbsp;</p>
        <div class="texto">
          <h4>Anexo II - Especificação e Tratamento de Dados Pessoais</h4>
        </div>
        <div class="texto">
          Para atendimento dos interesses contratuais comuns às partes, a Peça
          Rara realiza a coleta de dados pessoais de seus fornecedores e
          clientes.
        </div>
        <div class="texto">
          Abaixo, apresentamos as informações pessoais coletadas pela Peça Rara
          e suas respectivas finalidades:
        </div>
        <div class="texto">
          <ul>
            <li>
              <strong>CPF/CNPJ:</strong> usamos esse dado para permitir a sua
              identificação em nossos canais de atendimento, e como referência
              para o enriquecimento e atualização de seu cadastro;
            </li>
            <li>
              <strong>Nome:</strong> usamos esse dado para permitir a sua
              identificação em nossos canais de atendimento;
            </li>
            <li>
              <strong>Nome de tratamento:</strong> usamos esse dado para
              permitir um atendimento mais personalizado;
            </li>
            <li>
              <strong>Data de Nascimento:</strong> usamos esse dado para
              confirmação de sua identidade e para garantir a segurança
              infantil, de acordo com a legislação;
            </li>
            <li>
              <strong>Gênero:</strong> usamos esse dado para personalizar o seu
              contato, para divulgação de ações e soluções direcionadas para
              gêneros específicos e para realização de pesquisas segmentadas;
            </li>
            <li>
              <strong>Cliente/Fornecedor:</strong> usamos esse dado para
              permitir verificar a qual público de nossos serviços você
              pertence;
            </li>
            <li>
              <strong>Telefone:</strong> usamos esse dado para ações de
              divulgação de produtos e serviços, para fins promocionais, para a
              realização de pesquisas por meio de ligação ou de mensagem de
              texto, para responder às suas solicitações ou pedidos feitos em
              nossos canais ou para informá-lo de questões transacionais.
            </li>
            <li>
              <strong>E-mail:</strong> usamos esse dado para ações de divulgação
              de produtos e serviços, para realização de pesquisas, para
              responder às suas solicitações ou pedidos feitos em nossos canais
              ou para informá-lo de questões transacionais. Também realizamos o
              envio de e-mails com fins promocionais de acordo com a sua
              preferência e sua autorização, você pode entrar em contato conosco
              para revisar essa autorização.
            </li>
            <li>
              <strong>Contatos de redes sociais:</strong> usamos esse dado para
              permitir um atendimento mais personalizado, para ações de
              divulgação de produtos e serviços, para realização de pesquisas,
              para responder às suas solicitações ou pedidos feitos em nossos
              canais e e como referência para o enriquecimento e atualização de
              seu cadastro;
            </li>
            <li>
              <strong
                >Informações de localidade (CEP, UF, cidade, bairro e
                endereço):</strong
              >
              usamos esses dados para permitir a identificação de como podemos
              contatá-lo como destinatário em mala direta, para envio de
              cobrança judicial, direcionamento do atendimento em grandes
              cidades, para permitir um melhor planejamento na atuação em sua
              região.
            </li>
          </ul>
        </div>

        <div class="assinatura" *ngIf="assinar">
          <div class="assinaturaInterno">
            <h4>Assinatura <a class="xsign" (click)="assinar = false">x</a></h4>
            <ng-signature-pad
              doneButtonText="Salvar"
              clearButtonText="Limpar"
              format="base64"
              (done)="showImage($event)"
              *ngIf="!assinado"
            ></ng-signature-pad>
          </div>
        </div>
        <div class="row align-items-end" *ngIf="assinado">
          <div class="col col-xs-3">
            <div class="text-center">
              <img [src]="signatureImage" width="300" />
            </div>
            <div class="text-center">
              _________________________________________<br />
              {{ cont.fornecedor_razao_social }}<br />
              {{ cont.fornecedor_cpf_cnpj | CPFCNPJ }}
            </div>
          </div>
          <div class="col col-xs-3">
            <div class="text-center">
              <img
                [src]="currentUserTC.responsavel_loja.profile_sign_url"
                width="300"
              />
            </div>
            <div class="text-center">
              _________________________________________<br />
              {{ currentUserTC?.responsavel_loja.nome_completo }}<br />
              {{ currentUserTC?.loja.cpf_cnpj | CPFCNPJ }}
            </div>
          </div>
        </div>
        <div class="row mt-5" *ngIf="!erroCont">
          <div
            class="col-5 border"
            style="text-align: center; color: lightslategrey"
          >
            <div (click)="file1.click()">
              <br /><b>[ Anexar documento 1] </b><br />
            </div>
            <input
              #file1
              type="file"
              capture="camera"
              accept="image/*"
              id="cameraInput"
              name="cameraInput"
              text="Tirar foto"
              class="btn"
              (change)="onFileSelected($event, 1)"
              style="display: none"
            /><br />
            <img
              [src]="imagem1"
              *ngIf="imagem1"
              style="max-width: 100%; height: auto"
              (click)="imagem1 = null"
            />
          </div>
          <div class="col-2"></div>
          <div
            class="col-5 border"
            style="text-align: center; color: lightslategrey"
          >
            <div (click)="file2.click()">
              <br /><b>[ Anexar documento 2 ] </b><br />
            </div>
            <input
              #file2
              type="file"
              capture="camera"
              accept="image/*"
              id="cameraInput"
              name="cameraInput"
              text="Tirar foto"
              class="btn"
              (change)="onFileSelected($event, 2)"
              style="display: none"
            /><br />
            <img
              [src]="imagem2"
              *ngIf="imagem2"
              style="max-width: 100%; height: auto"
              (click)="imagem2 = null"
            />
          </div>
        </div>
        <p align="center" *ngIf="!erroCont">
          <button
            type="button"
            (click)="assinar = true"
            class="btn btn-primary mt-5 mx-2"
            *ngIf="!assinado"
          >
            Assinar
          </button>
          <button
            type="button"
            [disabled]="loading"
            (click)="submitAssinatura()"
            class="btn btn-primary mt-5"
            *ngIf="assinado"
          >
            <span
              *ngIf="loading"
              class="spinner-border spinner-border-sm mr-1"
            ></span>
            Confirmar assinatura
          </button>
          <button
            type="button"
            [disabled]="loading"
            (click)="assinar = true; assinado = false"
            class="btn btn-primary mt-5 mx-2"
            *ngIf="assinado"
          >
            <span
              *ngIf="loading"
              class="spinner-border spinner-border-sm mr-1"
            ></span>
            Assinar novamente
          </button>
          <button
            type="button"
            (click)="
              recibo = false; assinado = false; imagem1 = null; imagem2 = null
            "
            class="btn btn-primary mt-5"
            [disabled]="loading"
          >
            <span
              *ngIf="loading"
              class="spinner-border spinner-border-sm mr-1"
            ></span
            >Limpar
          </button>
        </p>
      </div>
    </div>
  </div>
</div>
