<div class="col-sm-12" align="center">
  <div class="col-sm-8">
    <h2 class="mb-3" align="center">Editar perfil - {{currentUserTC?.apelido}}</h2>
    <form [formGroup]="formPerfil" (ngSubmit)="submitForm()" class="form form-register">
      <div class="form-field" align="left">
        <label for="email
                        ">E-mail</label>
        <input type="text" class="form-control" formControlName="email" placeholder="Digite seu e-mail">
      </div>
      <div class="form-field" align="left">
        <label for="senha">Senha</label>
        <input type="password" class="form-control" formControlName="senha" placeholder="Digite a nova senha">
      </div>
      <div class="form-field" align="left">
        <label for="senha2">Confirmação de senha</label>
        <input type="password" class="form-control" formControlName="senha2" placeholder="Confirme a senha">
      </div>
      <div class="form-field" align="left">
        <label for="foto">Envie sua foto</label>
        <input type="file" class="form-control" formControlName="foto" placeholder="Envie sua foto"
          (change)="fileInput($event.target.files)">
      </div>
      <div class="form-field">
        <label for="assinatura">Assinatura</label>
        <p><img [src]="signatureImage || currentUserTC?.profile_sign_url" width="500" /></p>
      </div>
      <div class="form-field mt-3" align="center">
        <button type="button" (click)="assinar=true" class="btn btn-primary" *ngIf="!assinado">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Assinar</button>
        <button type="submit" class="btn btn-primary mx-2"><span *ngIf="loading"
            class="spinner-border spinner-border-sm mr-1"></span>Salvar perfil</button>
        <button type="button" [disabled]="loading" (click)="assinar = true; assinado = false;" class="btn btn-primary"
          *ngIf="assinado"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          Assinar novamente</button>
        <button type="button" *ngIf="assinado" (click)="assinado=false; signatureImage=''"
          class="btn btn-primary mx-2">Cancelar</button>
      </div>
    </form>
    <div class="assinatura" *ngIf="assinar">
      <div class="assinaturaInterno">
        <h4>Assinatura <a class="xsign" (click)="assinar=false;">x</a></h4>
        <ng-signature-pad doneButtonText="Salvar" clearButtonText="Limpar" (clear)="alert('oi')" format="base64"
          (done)="showImage($event)"></ng-signature-pad>
      </div>
    </div>
  </div>
</div>