import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthenticationService, AlertService, ReciboService, FornecedoresService } from 'src/app/_services';
import { User } from 'src/app/_models/user';

@Component({
  selector: 'app-compraassinatura',
  templateUrl: './compraassinatura.component.html',
  styleUrls: ['./compraassinatura.component.less']
})
export class CompraassinaturaComponent implements OnInit {
  currentUserTC: User;
  points = [];
  forn = [];
  signatureImage;

  formCompraProdutos = new FormGroup({
    valor: new FormControl('', Validators.required),
    nota: new FormControl('', Validators.required),
  });

  recibo: boolean = false;
  assinado: boolean = false;
  assinar: boolean = false;
  loading: boolean = false;
  botao: string = 'Carregando...';
  pdf;
  id: string;

  showImage(data) {
    this.signatureImage = data;
    this.assinar = false;
    this.assinado = true;
  }

  constructor(
    private router: ActivatedRoute,
    private fornService: FornecedoresService,
    private reciboService: ReciboService,
    private authenticationService: AuthenticationService,
    private alertService: AlertService
  ) {
    this.currentUserTC = this.authenticationService.currentUserTCValue;
  }

  validForm() {
    if (!this.forn['id'])
      return false
    else
      this.botao = 'Confirmar';
    return true;
  }


  submitForm() {
    this.alertService.clear();
    if (this.formCompraProdutos.value.valor > (this.forn['saldo']['saldo_liberado'] + this.forn['saldo']['saldo_bloqueado'])) {
      this.alertService.error('Valor não pode ser maior que o saldo para uso.');
      return;
    }
    this.recibo = true;
  }

  submitAssinatura() {
    this.loading = true;
    this.alertService.clear();
    const formData = new FormData();
    formData.append('user', this.currentUserTC.id.toString());
    formData.append('store', this.currentUserTC.loja_id.toString());
    formData.append('provider', this.forn['id']);
    formData.append('invoice', this.formCompraProdutos.get('nota').value);
    formData.append('value', this.formCompraProdutos.get('valor').value);
    formData.append('signature', this.signatureImage);
    this.reciboService.reciboCompra(this.forn['id'], formData).subscribe(
      (res) => {
        //console.log(res);
        this.alertService.success(res.message);
        this.getFornecedor();
        this.pdf = res.msg.img_pdf;
        this.loading = false;
        this.formCompraProdutos.reset();
      },
      (err) => {
        //console.log(err);
        this.alertService.error(err.error.message);
        this.loading = false;
      })
  }

  getFornecedor() {
    this.fornService.getDadosFornecedor(this.currentUserTC.loja_id, this.id)
      .subscribe(f => {
        this.forn = f.data;
        //console.log(this.forn);
        if(f.data.nf_devolucao) this.alertService.success('Existem notas para devolução (' + f.data.nf_devolucao.qtd_notas_devolucao + ')');
      },
        (err) => this.alertService.error(err.error.message));
  }

  ngOnInit() {
    this.id = this.router.snapshot.params.id;
    this.getFornecedor();
  }

}
