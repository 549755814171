<div class="container" *ngIf="!recibo">
  <h2>Adiantamento de saldo por motivo de compra</h2>
  <div class="row">
    <div class="col border-dark border-top border-left border-bottom rounded-left">
      <h4>Consignante</h4>
      <b>Código do contrato: </b>{{forn.fornecedor_contrato}}<br />
      <b>Nome do consignante: </b>{{forn.fornecedor_razao_social}}<br />
      <b>CPF: </b>{{forn.fornecedor_cpf_cnpj | CPFCNPJ}}<br />
      <b>Notas para devolução:</b> {{forn.nf_devolucao?.qtd_notas_devolucao || 0}}
    </div>
    <div class="col border-dark border-top border-right border-bottom rounded-right">
      <h4>Saldo resumido</h4>
      <b>Saldo disponível para saque: </b>{{forn.saldo?.saldo_liberado | currency:'BRL'}}<br />
      <b>Saldo bloqueado: </b>{{forn.saldo?.saldo_bloqueado | currency:'BRL'}}<br />
      <b>Saldo para uso: </b> {{forn.saldo?.saldo_liberado + forn.saldo?.saldo_bloqueado | currency:'BRL'}}
    </div>
  </div>

  <div class="row mt-5 mb-5">
    <div class="col border border-dark rounded-left">
      <form [formGroup]="formCompraProdutos" (ngSubmit)="submitForm()" class="form form-register">
        <h4>Dados da utilização do crédito</h4>
        <b>Valor a ser utilizado: </b><input type="number" class="form-control" formControlName="valor"
          placeholder="Entre com o valor">
        <b>Nota fiscal: </b><input type="number" class="form-control" formControlName="nota"
          placeholder="Entre com a nota fiscal">
        <div class="form-field mt-3 mb-3" align="center">
          <button type="submit" class="btn btn-primary" [disabled]="!validForm()||formCompraProdutos.invalid">{{botao}}</button>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="container" *ngIf="pdf" align="center">
  <a href="{{pdf}}" target="_blank">Recibo <i class="far fa-file-alt"></i></a>
</div>
<div class="container" *ngIf="recibo&&!pdf">
  <h2 class="mb-3">Recibo de adiantamento por motivo de compra</h2>
  <div class="mb-5" align="justify">
    Eu, <b>{{forn.fornecedor_razao_social}}</b>, inscrito(a) no CPF sob o número
    <b>{{forn.fornecedor_cpf_cnpj | CPFCNPJ}}</b> e no RG número
    <b>{{forn.fornecedor_rg_num}}/{{forn.fornecedor_rg_uf_cod}}</b>,
    recebi da <b>{{currentUserTC?.loja.nome_fantasia}}</b>, pessoa jurídica de direito privado,
    CNPJ
    <b>{{currentUserTC?.loja.cpf_cnpj | CPFCNPJ}}</b>, situada na <b>{{currentUserTC?.loja.end_logradouro}}
      {{currentUserTC?.loja.end_complemento}}, {{currentUserTC?.loja.end_bairro}}</b>, o valor de
    <b>{{formCompraProdutos.value.valor | currency:'BRL'}}</b>
    referentes à compra de produtos conforme nota fiscal <b>{{formCompraProdutos.value.nota}}</b> o qual foi abatido
    de meus créditos
    provenientes
    das vendas em consignação.
  </div>
  <div class="assinatura" *ngIf="assinar">
    <div class="assinaturaInterno">
      <h4>Assinatura <a class="xsign" (click)="assinar=false;">x</a></h4>
      <ng-signature-pad doneButtonText="Salvar" clearButtonText="Limpar" format="base64" (done)="showImage($event)"
        *ngIf="!assinado"></ng-signature-pad>
    </div>
  </div>
  <div class="row align-items-end" *ngIf="assinado">
    <div class="col col-xs-3">
      <div class="text-center"><img [src]="signatureImage" width="300" /></div>
      <div class="text-center">_________________________________________<br />
        {{forn.fornecedor_razao_social}}<br />
        {{forn.fornecedor_cpf_cnpj | CPFCNPJ}}
      </div>
    </div>
    <div class="col col-xs-3">
      <div class="text-center"><img [src]="currentUserTC.responsavel_loja.profile_sign_url" width="300" /></div>
      <div class="text-center">_________________________________________<br />
        {{currentUserTC?.responsavel_loja.nome_completo}}<br />
        {{currentUserTC?.loja.cpf_cnpj | CPFCNPJ}}
      </div>
    </div>
  </div>
  <p align="center"><button type="button" (click)="assinar=true" class="btn btn-primary mt-5 mx-2" *ngIf="!assinado">
      Assinar</button>
    <button type="button" [disabled]="loading" (click)="submitAssinatura();" class="btn btn-primary mt-5"
      *ngIf="assinado"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      Confirmar assinatura</button> <button type="button" [disabled]="loading"
      (click)="assinar = true; assinado = false;" class="btn btn-primary mt-5 mx-2" *ngIf="assinado"><span
        *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      Assinar novamente</button> <button type="button" (click)="recibo = false; assinado = false"
      class="btn btn-primary mt-5" [disabled]="loading"><span *ngIf="loading"
        class="spinner-border spinner-border-sm mr-1"></span>Cancelar</button></p>
</div>
