import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ContratoService {
  constructor(private http: HttpClient) { }

  getContratoPendente(loja_id: number, matricula?: string, nome?: string, cpf?: string): Observable<any> {
    const params = new HttpParams().set('matricula', matricula || '').set('nome', nome || '').set('cpf', cpf || '');
    return this.http.get(`${environment.apiUrl}/v1/contratos/pendentes/${loja_id}`, { params });
  }

  getContratoPendenteFornecedor(loja_id: number, matricula?: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/v1/contratos/pendentes/${loja_id}/view/${matricula}`);
  }

  salvarContrato(loja_id, formData: FormData): Observable<any> {
    return this.http.post(`${environment.apiUrl}/v1/contratos/pendentes/${loja_id}/update`, formData);
  }

}
