import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ReciboService {
  constructor(private http: HttpClient) { }

  reciboCompra(id_loja: number, formData: FormData): Observable<any> {
    return this.http.post(`${environment.apiUrl}/v1/recibos/reciboscompra/${id_loja}`, formData);
  }

  reciboSaque(id_loja: number, formData: FormData): Observable<any> {
    return this.http.post(`${environment.apiUrl}/v2/recibos/saque/${id_loja}`, formData);
  }

  extrato(id: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/extrato/${id}`);
  }

  extratoEmail(formData: FormData): Observable<any> {
    return this.http.post(`${environment.apiUrl}/extrato/email`, formData);
  }

  reciboEmail(formData: FormData): Observable<any> {
    return this.http.post(`${environment.apiUrl}/recibos/email`, formData);
  }

  reciboDevolucao(id_loja: number, matricula?: string, nome?: string, cpf?: string): Observable<any> {
    const params = new HttpParams().set('matricula', matricula || '').set('nome', nome || '').set('cpf', cpf || '');
    return this.http.get(`${environment.apiUrl}/v2/recibos/devolucao/${id_loja}`, { params });
  }

  reciboDevolucaoSalvar(id_loja: number, formData: FormData): Observable<any> {
    return this.http.post(`${environment.apiUrl}/v2/recibos/devolucao/${id_loja}`, formData);
  }

}
