<h2 class="mb-3" align="center">Incluir documentos</h2>
<div class="row">
  <div class="col-6">
    <b>Identidade:</b><br />
    <input type="file" capture="camera" accept="image/*" id="cameraInput" name="cameraInput" text="Tirar foto"
      class="btn" (change)="onFileSelected($event)"><br />
    <button type="button" class="btn btn-primary" (click)="onUpload(1)">Salvar identidade</button><br /><br />
    <img [src]="imagem1" *ngIf="imagem1" width="200">
  </div>
  <div class="col-6">
    <b>Comprovante de residência:</b><br />
    <input type="file" capture="camera" accept="image/*" id="cameraInput" name="cameraInput" text="Tirar foto"
      class="btn" (change)="onFileSelected($event)"><br />
    <button type="button" class="btn btn-primary" (click)="onUpload(2)">Salvar comprovante de
      residência</button><br /><br />
      <img [src]="imagem2" *ngIf="imagem2" width="200">
  </div>
</div>
<div class="row justify-content-center">
  <input type="button" value="Salvar" (click)="salvarImagens()" class="btn btn-primary">
</div>