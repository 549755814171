<!-- nav -->
<nav class="navbar navbar-expand-lg navbar-dark bg-dark" *ngIf="currentUserTC">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
    aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Alterna navegação">
    <span class="navbar-toggler-icon"></span>
  </button>
  <a class="navbar-brand" routerLink="/">Peça Rara</a>
  <div class="collapse navbar-collapse" id="navbarNavDropdown">
    <ul class="navbar-nav">
      <li class="nav-item active">
        <a class="nav-link" routerLink="/profile"><i class="fas fa-user"></i> Perfil</a>
      </li>
      <li class="nav-item active dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <i class="fa fa-file-contract"></i> Contratos
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" routerLink="/pesquisaforn/contratopendente"><i class="fa file-signature"></i>
            Pendentes</a>
        </div>
      </li>
      <li class="nav-item active dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink2" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <i class="fa fa-check-circle"></i> Recibos
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink2">
          <a class="dropdown-item" routerLink="/pesquisaforn/compraassinatura"><i class="fa fa-cart-plus"></i> Compra de
            Produtos</a>
          <a class="dropdown-item" routerLink="/pesquisaforn/saqueassinatura"><i class="fa fa-hand-holding-usd"></i>
            Solicitação de Saque</a>
          <a class="dropdown-item" routerLink="/pesquisaforn/devolucao"><i class="fas fa-undo"></i> Devolução</a>
        </div>
      </li>
      <li class="nav-item active">
        <a class="nav-link" routerLink="/pesquisaforn/extratodetalhe" [queryParams]=""><i class="fa fa-receipt"></i>
          Extrato</a>
      </li>
      <li class="nav-item active">
        <a class="nav-link" routerLink="/relatoriorecibosaque" [queryParams]=""><i class="fa fa-receipt"></i>
          Relatórios</a>
      </li>
      <li class="nav-item active">
        <a class="nav-link" (click)="logout()">Sair</a>
      </li>
    </ul>
  </div>
</nav>

<!-- main app container -->
<div class="jumbotron">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <alert></alert>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

<!-- credits -->
<div class="text-center">
  <p style="color:darkgray">
    Peça Rara {{ano}} {{version}} - Todos os direitos reservados <span *ngIf="currentUserTC"> - {{currentUserTC?.nome}}
      [{{currentUserTC?.loja.nome_fantasia}}]</span>
  </p>
</div>
