import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { appRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { AlertComponent } from './_components/alert.component';
import { DocumentoComponent } from './contrato/anexos/documento.component';
import { SignaturePadModule } from '@ng-plus/signature-pad';
import { AssinaturaComponent } from './assinatura/assinatura.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Contrato } from './_models/contrato';
import { environment } from '../environments/environment';
import { ContratopendenteComponent } from './contrato/contratopendente/contratopendente.component';
import { ExtratodetalheComponent } from './recibo/extratodetalhe/extratodetalhe.component';
import { CompraassinaturaComponent } from './recibo/compraassinatura/compraassinatura.component';
import { SaqueassinaturaComponent } from './recibo/saqueassinatura/saqueassinatura.component';
import { DevolucaoComponent } from './recibo/devolucao/devolucao.component';
import { RecibosaqueComponent } from './relatorios/recibosaque/recibosaque.component';
import { RfidComponent } from './rfid/rfid.component';
import { ProfileComponent } from './profile/profile.component';
import { PesquisafornComponent } from './pesquisaforn/pesquisaforn.component';

import { CPFCNPJ } from './_pipes';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';;
import { LgpdComponent } from './lgpd/lgpd.component'

registerLocaleData(localePt, 'pt-BR');

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        appRoutingModule,
        SignaturePadModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ],
    declarations: [
        AppComponent,
        HomeComponent,
        LoginComponent,
        RegisterComponent,
        AlertComponent,
        AssinaturaComponent,
        DocumentoComponent,
        ContratopendenteComponent,
        CompraassinaturaComponent,
        SaqueassinaturaComponent,
        RfidComponent,
        CPFCNPJ,
        ProfileComponent,
        ExtratodetalheComponent,
        PesquisafornComponent,
        DevolucaoComponent,
        RecibosaqueComponent,
        LgpdComponent
    ],
    providers: [
        Contrato,
        {
            provide: LOCALE_ID,
            useValue: "pt-BR"
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { };
