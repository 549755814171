import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserTCSubject: BehaviorSubject<any>;
    public currentUserTC: Observable<any>;

    constructor(private http: HttpClient) {
        this.currentUserTCSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUserTC')));
        this.currentUserTC = this.currentUserTCSubject.asObservable();
    }

    public get currentUserTCValue() {
        return this.currentUserTCSubject.value;
    }

    login(username, password) {
        const formData = new FormData();
        formData.append('login', username);
        formData.append('password', password);
        return this.http.post<any>(`${environment.apiUrl}/users`, formData)
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUserTC', JSON.stringify(user.msg.data));
                this.currentUserTCSubject.next(user.msg.data);
                return user.msg.data;
            }));
    }

    logout() {
        // remove user from local storage and set current user to null
        localStorage.removeItem('currentUserTC');
        this.currentUserTCSubject.next(null);
    }
}

