import { Injectable } from '@angular/core';

@Injectable()
export class Contrato {

   textoContrato: string = `<table border="0" cellpadding="0"  cellspacing="0" style="width: 100%; " >
    <tr>
        <td>
            <img src="https://www.pecararabrecho.com.br/wp-content/uploads/2018/12/pr_-_logotipo.gif" style="width: 100%; max-width: 150px;">
        </td>
        <td style="border-right: 1px solid white">
            <H1>CONTRATO DE VENDA EM CONSIGNAÇÃO</H1>
        </td>
    </tr>
    <tr>
        <td colspan="2">
            <h2>CONSIGNANTE:</h2>
        </td>
    </tr>
    <tr>
        <td colspan="2">
            <b>Código do contrato:</b> <span id="contratoCodigo">001</span>
        </td>
    </tr>
    <tr>
        <td colspan="2">
            <b>Nome do Consignante:</b> <span id="contratoConsignante">XXXXXXXXXXXXXXXX</span>
        </td>
    </tr>
    <tr>
        <td colspan="2">
            <b>Endereço:</b> <span id="contratoEndereco">SQS 409 bloco I</span>
        </td>
    </tr>
    <tr>
        <td colspan="2">
            <b>CPF:</b> <span id="contratoCPF">785.665.458-88</span>
        </td>
    </tr>
    <tr>
        <td colspan="2">
            <b>E-mail:</b> <span id="contratoEmail">no@email.com</span>
        </td>
    </tr>
    <tr>
        <td colspan="2">
            <b>Telefones:</b> <span id="contratoTelefone">61 92343-1134</span>
        </td>
    </tr>
    <tr>
        <td colspan="2">
            <b>Representante:</b> <span id="contratoRepresentante">Repr Comercial</span>
        </td>
    </tr>
    <tr>
        <td colspan="2">
            &nbsp;
        </td>
    </tr>
    <tr>
        <td colspan="2">
            <h2>CONSIGNATÁRIA</h2>
        </td>
    </tr>
    <tr>
        <td colspan="2">
            <b>PR MAIS COMÉRCIO VAREJISTA DE ARTIGOS NOVOS E USADOS LTDA-ME</b>, pessoa jurídica de direito privado, CNPJ 11.832.781/0001-05, situada na SHCS CLS 408 Bloco C Loja 34/29, Asa Sul, Brasília, DF.
        </td>
    </tr>
    <tr>
        <td colspan="2">
            <ol>
                <li>
                    O(A) CONSIGNANTE disponibiliza à CONSIGNATÁRIA, mediante consignação o(s) item(s) relacionados em seu código de cadastro;
                </li>
                <li>
                    O(A) CONSIGNANTE declara para todos os efeitos ser proprietário do(s) referido(s) item(s), sendo de sua inteira responsabilidade a origem e/ou procedência;
                </li>
                <li>
                    Cabe à CONSIGNATÁRIA a boa guarda do(s) item(s) descrito(s), assumindo a responsabilidade por eventuais danos (incluindo os móveis), excetuando situações de caso fortuito ou força maior, tais como fenômenos da natureza, além de incêndio, catástrofe, saques, entre outros;
                </li>
                <li>
                    A CONSIGNATÁRIA colocará em exposição o(s) item(s) para venda a terceiros após avaliação da condição geral, estado de conservação, valor agregado e demais fatores determinantes do preço. O prazo para a exposição também dependerá do espaço disponível no interior da loja, porém a CONSIGNATÁRIA dispensará seus melhores esforços para expor seu (s) item (s) o mais breve possível;
                </li>
                <li>
                    O resultado das vendas do(s) item(s) ficará disponível a partir do mês subsequente à venda, devendo o(a) CONSIGNANTE comparecer à loja ou enviar representante autorizado;
                </li>
                <li>
                    O pagamento poderá ser efetuado em dinheiro (para valores inferiores a R$ 100,00 reais), em cheque ou em transferência bancária. Para transferências que não sejam do Banco do Brasil, serão descontados o valor do DOC ou do TED e o prazo para todo o tipo de transferência poderá ser de até 7 (sete) dias úteis;
                </li>
                <li>A partir dos 180 (cento e oitenta) dias do cadastramento do(s) item(s), o CONSIGNANTE, [ ] autoriza [ ] não autoriza a CONSIGNATÁRIA a reduzir em 40% (quarenta pontos percentuais) o valor do (s) produto (s) pactuado (s) anteriormente, tendo como benefícios:<br>
                    - Redução do valor dos produtos após 180 (cento e oitenta dias);<br>
                    - Aumentar a probabilidade de comercialização de seus produtos;<br>
                    - Evitar recorrer ao processo de devolução.
                <li>
                    A CONSIGNATÁRIA ressalva o direito de devolver o(s) item(s) a qualquer tempo, mediante comunicação;
                </li>
                <li>
                    O (A) CONSIGNANTE, conforme clausula anterior, receberá um comunicado, via e-mail e/ou aviso em aplicativo próprio, para providenciar a retirada do (s) item (s) não comercializado (s) no prazo de 60 (sessenta dias);
                </li>
                <li>
                    Caso o CONSIGNANTE não compareça para a retirada de seu (s) produto (s) no prazo mencionado no item anterior, a CONSIGNATÁRIA os encaminhará para doação à entidades e instituições filantrópicas reconhecidamente idôneas;
                </li>
                <li>
                    Caso o(a) CONSIGNANTE queira retirar a (s) peça (s) da loja, deverá informar a CONSIGNATÁRIA com um prazo mínimo de trinta dias de antecedência. Na hipótese da CONSIGNATÁRIA não encontrar a (s) peça (s) solicitada (s), por qualquer que seja o motivo, a (s) peça (s) será (ão) lançada (s) como vendida (s) e o CONSIGNANTE receberá o valor correspondente a sua parte. Fica terminantemente proibido o (a) CONSIGNANTE procurar seu (s) produto (s) no interior da loja, tanto nos espaços de exposição dos produtos como nos espaços restritos do estabelecimento. Tal conduta caracterizará quebra imediata do contrato;
                </li>
                <li>
                    12- A consignação se efetivará mediante um percentual de 50% (cinquenta por cento) do valor líquido para cada uma das partes, após deduzidos os ônus de administradoras de cartão de débito/crédito (taxa sobre vendas, juros de antecipação e aluguel de leitora) pré-fixados no custo mínimo de operação em 3%, além dos tributos incidentes na venda, sendo os demais custos subsidiados pela CONSIGNATÁRIA;
                </li>
            </ol>
        </td>
    </tr>
    <tr>
        <td colspan="2">
            As partes declaram expressamente que tomaram ciência do inteiro teor do contrato, bem como que lhes fora entregue uma via com idêntico teor via e-mail.
        </td>
    </tr>
    <tr>
        <td colspan="2">
            <b>
                As partes elegem o foro da Circunscrição Judiciária de Brasília, DF, para dirimir quaisquer dúvidas ou litígios decorrentes deste instrumento, renunciando a qualquer outro, por mais privilegiado que seja.
            </b>
        </td>
    </tr>
    <tr>
        <td colspan="2">
            <b>
                Brasília, DF, XX de YYYYYYYY de XXXX.
            </b>
        </td>
    </tr>
    <tr>
        <td colspan="2">
            <table width="100%" height="200" border="0" cellpadding="0" cellspacing="0" align="center">
                <tr>
                    <td id="assinatura" width="30%" align="center" valign="bottom" style="border-bottom: 1px solid black;text-align: center; font-weight: bolder"></td>
                    <td width="40%">&nbsp;
					</td>
                    <td width="30%" align="center" valign="bottom" style="border-bottom: 1px solid black;text-align: center; font-weight: bolder"></td>
                </tr>
                <tr height="50">
                    <td width="30%" align="center" valign="bottom" style="text-align: center; font-weight: bolder">CONSIGNANTE</td>
                    <td width="40%">&nbsp;
					</td>
                    <td width="30%" align="center" valign="bottom" style="text-align: center; font-weight: bolder">CONSIGNATÁRIA</td>
                </tr>
            </table>
        </td>
    </tr>
</table>`

} 