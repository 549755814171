import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { User } from 'src/app/_models/user';
import { AuthenticationService, AlertService, FornecedoresService, ReciboService, ContratoService } from 'src/app/_services';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-pesquisaforn',
  templateUrl: './pesquisaforn.component.html',
  styleUrls: ['./pesquisaforn.component.less']
})
export class PesquisafornComponent implements OnInit {

  formForn = new FormGroup({
    codigoCliente: new FormControl(''),
    nomeCliente: new FormControl(''),
    cpf: new FormControl(''),
  });

  currentUserTC: User;
  rota;
  titulo = '';

  loading: boolean = false;
  tabela: boolean = false;
  forn: any = [];
  dev: any = [];
  cont: any = [];

  constructor(
    private route: Router,
    private router: ActivatedRoute,
    private fornService: FornecedoresService,
    private contService: ContratoService,
    private reciboService: ReciboService,
    private authenticationService: AuthenticationService,
    private alertService: AlertService) {
    this.route.routeReuseStrategy.shouldReuseRoute = () => false;
    this.currentUserTC = this.authenticationService.currentUserTCValue;
    //this.currentUserTC.loja_id = 2;
    //console.log(this.currentUserTC);
    this.alertService.clear();
    this.tabela = false;
    this.router.params.subscribe(param => {
      this.rota = param['rota'];
      switch (this.rota) {
        case 'extratodetalhe':
          this.titulo = 'Extrato de Movimentação';
          break;
        case 'compraassinatura':
          this.titulo = 'Compra de Produtos';
          break;
        case 'saqueassinatura':
          this.titulo = 'Solicitação de saque';
          break;
        case 'devolucao':
          this.titulo = 'Devolução';
          break;
        case 'contratopendente':
          this.titulo = 'Contratos pendentes';
          break;
      }
    })
  }

  validForm() {
    if ((this.formForn.get('codigoCliente').value.length < 1 || this.formForn.get('codigoCliente').value == null) &&
      (this.formForn.get('nomeCliente').value.length < 4 || this.formForn.get('nomeCliente').value == null) &&
      (this.formForn.get('cpf').value.length < 1 || this.formForn.get('cpf').value == null)) {
      return false;
    }
    return true;
  }

  sync() {
    this.alertService.clear();
    this.loading = true;
    this.fornService.sync(this.currentUserTC.loja_id)
      .subscribe(f => {
        this.alertService.success(f.message);
      },
        (err) => this.alertService.error(err.error.message))
      .add(() => this.loading = false);
  }

  verificaItemSelecionado() {
    return this.dev.filter(x => x.itemSelecionado).length>0;
  }

  verificaTotalSelecionado() {
    return this.dev.filter(x => x.itemSelecionado).length;
  }

  selecionarTodas() {
    this.dev.map(x => x.itemSelecionado = !x.itemSelecionado);
  }

  assinarItens() {
    let itens = this.dev.filter(x => x.itemSelecionado).map(x => x.nf_num);
    this.route.navigate([`/devolucao/${this.dev[0].maqplan_fornecedor_id}/${itens}`]);
  }

  submitForm() {
    this.alertService.clear();
    this.loading = true;
    this.tabela = true;
    this.forn = [];
    this.dev = [];
    switch (this.titulo) {
    case 'Devolução':
      this.reciboService.reciboDevolucao(this.currentUserTC.loja_id, this.formForn.value.codigoCliente, this.formForn.value.nomeCliente, this.formForn.value.cpf)
      .subscribe(d => {
        this.dev = d.data;
        this.dev.map(x => x.itemSelecionado = false);
        //console.log(this.dev);
      },
        (err) => this.alertService.error(err.error.message))
      .add(() => this.loading = false);
      break;
    case 'Contratos pendentes':
      this.contService.getContratoPendente(this.currentUserTC.loja_id, this.formForn.value.codigoCliente, this.formForn.value.nomeCliente, this.formForn.value.cpf)
      .subscribe(c => {
        this.cont = c.data;
        //console.log(this.forn);
      },
        (err) => this.alertService.error(err.error.message))
      .add(() => this.loading = false);
      break;
    default:
      this.fornService.getFornecedor(this.currentUserTC.loja_id, this.formForn.value.codigoCliente, this.formForn.value.nomeCliente, this.formForn.value.cpf)
      .subscribe(f => {
        this.forn = f.data;
        //console.log(this.forn);
      },
        (err) => this.alertService.error(err.error.message))
      .add(() => this.loading = false);
    }
  }

  ngOnInit() {
  }

}
