<div align="justify">
<p align="center"><b><u><h1>Especificação e Tratamento de Dados Pessoais</h1></u></b></p>
<p class="texto">Para atendimento dos interesses contratuais comuns às partes, a Peça Rara realiza a coleta de dados
  pessoais de seus fornecedores e clientes.</p>

<p class="texto">Abaixo, apresentamos as informações pessoais coletadas pela Peça Rara e suas respectivas finalidades:</p>

<ul>
  <li><b>CPF/CNPJ:</b> usamos esse dado para permitir a sua identificação em nossos canais de atendimento e como referência para o
    enriquecimento e atualização de seu cadastro;</li>
  <li><b>Nome:</b> usamos esse dado para permitir a sua identificação em nossos canais de atendimento;</li>
  <li><b>Nome de tratamento:</b> usamos esse dado para permitir um atendimento mais personalizado;</li>
  <li><b>Data de Nascimento:</b> usamos esse dado para confirmação de sua identidade e para garantir a segurança infantil, de acordo
    com a legislação;</li>
  <li><b>Gênero:</b> usamos esse dado para personalizar o seu contato, para divulgação de ações e soluções direcionadas para gêneros
    específicos e para realização de pesquisas segmentadas;</li>
  <li><b>Cliente/Fornecedor:</b> usamos esse dado para permitir verificar a qual público de nossos serviços você pertence;</li>
  <li><b>Telefone:</b> usamos esse dado para ações de divulgação de produtos e serviços, para fins promocionais, para a realização de
    pesquisas por meio de ligação ou de mensagem de texto, para responder às suas solicitações ou pedidos feitos em nossos
    canais ou para informá-lo de questões transacionais.</li>
  <li><b>E-mail:</b> usamos esse dado para ações de divulgação de produtos e serviços, para realização de pesquisas, para responder
    às suas solicitações ou pedidos feitos em nossos canais ou para informá-lo de questões transacionais. Também realizamos
    o envio de e-mails com fins promocionais de acordo com a sua preferência e sua autorização, você pode entrar em contato
    conosco para revisar essa autorização.</li>
  <li><b>Contatos de redes sociais:</b> usamos esse dado para permitir um atendimento mais personalizado, para ações de divulgação de
    produtos e serviços, para realização de pesquisas, para responder às suas solicitações ou pedidos feitos em nossos
    canais e e como referência para o enriquecimento e atualização de seu cadastro;</li>
  <li><b>Informações de localidade (CEP, UF, cidade, bairro e endereço):</b> usamos esses dados para permitir a identificação de como
    podemos contatá-lo como destinatário em mala direta, para envio de cobrança judicial, direcionamento do atendimento em
    grandes cidades, para permitir um melhor planejamento na atuação em sua região.</li>
</ul>
<p align="center"><button type="button" class="btn btn-primary mx-2" onclick="history.back()">Voltar</button></p>
</div>
