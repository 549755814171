import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/_models/user';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService, AlertService, ContratoService } from 'src/app/_services';

@Component({
  selector: 'app-contratopendente',
  templateUrl: './contratopendente.component.html',
  styleUrls: ['./contratopendente.component.less']
})
export class ContratopendenteComponent implements OnInit {

  currentUserTC: User;
  points = [];
  cont: any = [];
  signatureImage;
  selectedFile: any = null;
  imagem1: any = null;
  imagem2: any = null;
  erroCont: boolean = true;
  option_reducao: boolean = true;

  recibo: boolean = false;
  assinado: boolean = false;
  assinar: boolean = false;
  loading: boolean = false;
  dataAtual: string;
  id: string;
  pdf;

  showImage(data) {
    this.signatureImage = data;
    this.assinar = false;
    this.assinado = true;
  }

  onFileSelected(event, img) {
    this.selectedFile = event.target.files[0];
    this.onUpload(img);
  }

  onUpload(img) {
    if (this.selectedFile) {
      let reader = new FileReader();
      reader.readAsDataURL(this.selectedFile);
      reader.onload = () => {
        if (img == 1)
          this.imagem1 = reader.result;
        else
          this.imagem2 = reader.result;
      };
    }
  }

  constructor(
    private router: ActivatedRoute,
    private contService: ContratoService,
    private authenticationService: AuthenticationService,
    private alertService: AlertService
  ) {
    this.currentUserTC = this.authenticationService.currentUserTCValue;
    //console.log(this.currentUserTC);
  }

  submitAssinatura() {
    this.loading = true;
    this.alertService.clear();
    const formData = new FormData();
    formData.append('user', this.currentUserTC.id.toString());
    formData.append('provider', this.cont['id']);
    formData.append('signature', this.signatureImage);
    if(this.imagem1) formData.append('file01', this.imagem1);
    if(this.imagem2) formData.append('file02', this.imagem2);
    formData.append('option_reducao', this.option_reducao.toString());
    this.contService.salvarContrato(this.currentUserTC.loja_id.toString(), formData).subscribe(
      (res) => {
        //console.log(res);
        this.alertService.success(res.message);
        this.pdf = res.msg.img_pdf
        this.loading = false;
      },
      (err) => {
        //console.log(err);
        this.alertService.error(err.error.message);
        this.loading = false;
      }
    ).add(() => window.scrollTo(0, 0))
  }

  getContrato() {
    console.log(this.currentUserTC)
    this.dataAtual = (new Date()).toLocaleDateString([], { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
    this.contService.getContratoPendenteFornecedor(this.currentUserTC.loja_id, this.id)
      .subscribe(c => {
        this.cont = c.data;
        this.erroCont = false;
      },
        (err) => {
          this.alertService.error(err.error.message);
        }
      )
  }

  ngOnInit() {
    this.id = this.router.snapshot.params.id;
    this.getContrato();
  }

}
