<div class="container" *ngIf="pdf" align="center">
  <!-- <a href="{{pdf}}" target="_blank">Recibo <i class="far fa-file-alt"></i></a> -->
</div>
<div class="container" *ngIf="!pdf">
  <h2 class="mb-3">Recibo de devolução de mercadorias</h2>
  <div class="mb-5" align="justify">
    Eu, <b>{{dev[0]?.fornecedor_razao_social}}</b>, inscrito(a) no CPF sob o número
    <b>{{dev[0]?.fornecedor_cpf_cnpj | CPFCNPJ}}</b>, declaro ter recebido nesta data, da empresa
    <b>{{currentUserTC?.loja.nome_fantasia}}</b>, pessoa jurídica de direito privado,
    CNPJ <b>{{currentUserTC?.loja.cpf_cnpj | CPFCNPJ}}</b>, situada na <b>{{currentUserTC?.loja.end_logradouro}}
      {{currentUserTC?.loja.end_complemento}}, {{currentUserTC?.loja.end_bairro}}</b>, a(s)
    seguinte(s)
    mercadoria(s) conforme nota(s) fiscal(is) listadas abaixo:<br /><br />
    <div *ngFor="let n of dev" class="mx-4 mt-3">
      <b>Nota fiscal {{n.nf_num}}</b>
      <div *ngFor="let i of n.itens" class="mx-4">◾ {{i.item_cod}}: ({{i.item_qtde}}x) {{i.item_desc}} (valor
        unitário:
        {{i.valor_fornecedor | currency:'BRL'}})</div>
      </div>
    </div>

  <div class="assinatura" *ngIf="assinar">
    <div class="assinaturaInterno">
      <h4>Assinatura <a class="xsign" (click)="assinar=false;">x</a></h4>
      <ng-signature-pad doneButtonText="Salvar" clearButtonText="Limpar" format="base64" (done)="showImage($event)"
        *ngIf="!assinado"></ng-signature-pad>
    </div>
  </div>
  <div class="row align-items-end" *ngIf="assinado">
    <div class="col col-xs-3">
      <div class="text-center"><img [src]="signatureImage" width="300" /></div>
      <div class="text-center">_________________________________________<br />
        {{dev[0]?.fornecedor_razao_social}}<br />
        {{dev[0]?.fornecedor_cpf_cnpj | CPFCNPJ}}
      </div>
    </div>
    <div class="col col-xs-3">
      <div class="text-center"><img [src]="currentUserTC.responsavel_loja.profile_sign_url" width="300" /></div>
      <div class="text-center">_________________________________________<br />
        {{currentUserTC?.responsavel_loja.nome_completo}}<br />
        {{currentUserTC?.loja.cpf_cnpj | CPFCNPJ}}
      </div>
    </div>
  </div>
  <p align="center"><button type="button" (click)="assinar=true" class="btn btn-primary mt-5 mx-2" *ngIf="!assinado"
      [disabled]="loading||dev.length==0"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      {{botao}}</button>
    <button type="button" (click)="submitAssinatura();" class="btn btn-primary mt-5" *ngIf="assinado"
      [disabled]="loading"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      Confirmar assinatura</button> <button type="button" (click)="assinar = true; assinado = false;"
      class="btn btn-primary mt-5 mx-2" *ngIf="assinado" [disabled]="loading"><span *ngIf="loading"
        class="spinner-border spinner-border-sm mr-1"></span>
      Assinar novamente</button> <button type="button" routerLink="/pesquisaforn/devolucao" class="btn btn-primary mt-5"
      [disabled]="loading"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Cancelar</button>
  </p>
</div>
