<h2>Registro</h2>
<form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
        <label for="firstName">Nome</label>
        <input type="text" formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
        <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
            <div *ngIf="f.firstName.errors.required">Nome é obrigatório</div>
        </div>
    </div>
    <div class="form-group">
        <label for="lastName">Sobrenome</label>
        <input type="text" formControlName="lastName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
        <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
            <div *ngIf="f.lastName.errors.required">Sobrenome é obrigatório</div>
        </div>
    </div>
    <div class="form-group">
        <label for="username">Usuário</label>
        <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
            <div *ngIf="f.username.errors.required">Usuário é obrigatório</div>
        </div>
    </div>
    <div class="form-group">
        <label for="password">Senha</label>
        <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
            <div *ngIf="f.password.errors.required">Senha é obrigatório</div>
            <div *ngIf="f.password.errors.minlength">A senha precisa ter no mínimo 6 caracteres</div>
        </div>
    </div>
    <div class="form-group">
        <button [disabled]="loading" class="btn btn-primary">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Registrar
        </button>
        <a routerLink="/login" class="btn btn-link">Cancelar</a>
    </div>
</form>
