<div class="container" *ngIf="!recibo">
  <h2>Solicitação de saque</h2>
  <div class="row">
    <div class="col border-dark border-top border-left border-bottom rounded-left">
      <h4>Consignante</h4>
      <b>Código do contrato: </b>{{forn.fornecedor_contrato}}<br />
      <b>Nome do consignante: </b>{{forn.fornecedor_razao_social}}<br />
      <b>CPF: </b>{{forn.fornecedor_cpf_cnpj | CPFCNPJ}}<br />
      <b>Notas para devolução:</b> {{forn.nf_devolucao?.qtd_notas_devolucao || 0}}
    </div>
    <div class="col border-dark border-top border-right border-bottom rounded-right">
      <h4>Saldo resumido</h4>
      <p><b>Saldo disponível para saque: </b>{{forn.saldo?.saldo_liberado | currency:'BRL'}}</p>
    </div>
  </div>
  <div class="row mt-5 mb-5">
    <div class="col border border-dark rounded-left">
      <form [formGroup]="formSaque" (ngSubmit)="submitForm()" class="form form-register">
        <h4>Dados da utilização do crédito</h4>
        <b>Dinheiro: </b><input type="number" step="0.01" class="form-control" formControlName="valorD"
          placeholder="Entre com o valor do dinheiro">
        <b>Cheque: </b><input type="number" step="0.01" class="form-control" formControlName="valorC"
          placeholder="Entre com o valor do cheque">
        <b>Número do cheque: </b><input type="number" step="0.01" class="form-control" formControlName="check_num"
          placeholder="Entre com o número do cheque">
        <b>Transferência:</b><br /> <small *ngIf="forn.banco">({{forn.banco?.banco_nome}} - Ag: {{forn.banco_ag}} CC:
          {{forn.banco_cc}})</small> <small><b> Taxa de transferência: {{forn.taxa_ted_doc | currency:'BRL'}}</b></small>
        <input type="number" step="0.01" class="form-control" formControlName="valorT"
          placeholder="Entre com o valor da Transferência">
        <b>PIX: </b> <small *ngIf="forn.chave_pix"> Chave {{forn.chave_pix}}</small>
        <input type="number" step="0.01" class="form-control" formControlName="valorP"
          placeholder="Entre com o valor do PIX">
        <b>Cartão PR: </b><input type="number" step="0.01" class="form-control" formControlName="valorCT"
          placeholder="Entre com o valor no cartão">
        <div class="form-field mt-3 mb-3" align="center">
          <button type="submit" class="btn btn-primary" [disabled]="!validForm()">{{botao}}</button>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="container" *ngIf="pdf" align="center">
  <a href="{{pdf}}" target="_blank">Recibo <i class="far fa-file-alt"></i></a>
</div>
<div class="container" *ngIf="recibo&&!pdf">
  <h2 class="mb-3">Recibo de solicitação de saque</h2>
  <div class="mb-5" align="justify">
    Eu, <b>{{forn.fornecedor_razao_social}}</b>, inscrito(a) no CPF sob o número
    <b>{{forn.fornecedor_cpf_cnpj | CPFCNPJ}}</b><span *ngIf="forn.fornecedor_rg_num"> e no RG número
    <b>{{forn.fornecedor_rg_num}}/{{forn.fornecedor_rg_uf_cod}}</b></span>,
    recebi da <b>{{currentUserTC?.loja.nome_fantasia}}</b>, pessoa jurídica de direito privado, CNPJ
    <b>{{currentUserTC?.loja.cpf_cnpj | CPFCNPJ}}</b>, situada na <b>{{currentUserTC?.loja.end_logradouro}}
      {{currentUserTC?.loja.end_complemento}}, {{currentUserTC?.loja.end_bairro}}</b>, o valor de
    <b>{{fCurr(valorTotal) | currency:'BRL'}}</b>
    referentes à
    solicitação de
    saque o qual será abatido de meus créditos provenientes das vendas em consignação conforme abaixo
    descrito:<br /><br />

    <span class="mx-4" *ngIf="fCurr(formSaque.value.valorD)>0.0"><b>Dinheiro:</b>
      {{fCurr(formSaque.value.valorD) | currency:'BRL'}}<br></span>
    <span class="mx-4" *ngIf="fCurr(formSaque.value.valorC)>0.0"><b>Cheque:</b>
      {{fCurr(formSaque.value.valorC) | currency:'BRL'}} | <b>Num. Cheque:</b>
      {{formSaque.value.check_num}}<br></span>
    <span class="mx-4" *ngIf="fCurr(formSaque.value.valorT)>0.0"><b>Transferência:</b>
      {{fCurr(formSaque.value.valorT) | currency:'BRL'}}<br></span>
    <span class="mx-4" *ngIf="fCurr(formSaque.value.valorT)>0.0&&fCurr(forn.taxa_ted_doc)>0.0"><b>Taxa de transferência:</b>
      {{fCurr(forn.taxa_ted_doc) | currency:'BRL'}}<br></span>
    <span class="mx-4" *ngIf="fCurr(formSaque.value.valorP)>0.0"><b>Transferência PIX:</b>
      {{fCurr(formSaque.value.valorP) | currency:'BRL'}}<br></span>
    <span class="mx-4" *ngIf="fCurr(formSaque.value.valorCT)>0.0"><b>Cartão PR:</b>
      {{fCurr(formSaque.value.valorCT) | currency:'BRL'}}<br></span>
    <!--<span class="mx-4"><b>Total:</b>
      {{fCurr(valorTotal) | currency:'BRL'}}<br></span>//-->
  </div>
  <div class="assinatura" *ngIf="assinar">
    <div class="assinaturaInterno">
      <h4>Assinatura <a class="xsign" (click)="assinar=false;">x</a></h4>
      <ng-signature-pad doneButtonText="Salvar" clearButtonText="Limpar" format="base64" (done)="showImage($event)"
        *ngIf="!assinado"></ng-signature-pad>
    </div>
  </div>
  <div class="row align-items-end" *ngIf="assinado">
    <div class="col col-xs-3">
      <div class="text-center"><img [src]="signatureImage" width="300" /></div>
      <div class="text-center">_________________________________________<br />
        {{forn.fornecedor_razao_social}}<br />
        {{forn.fornecedor_cpf_cnpj | CPFCNPJ}}
      </div>
    </div>
    <div class="col col-xs-3">
      <div class="text-center"><img [src]="currentUserTC.responsavel_loja.profile_sign_url" width="300" /></div>
      <div class="text-center">_________________________________________<br />
        {{currentUserTC?.responsavel_loja.nome_completo}}<br />
        {{currentUserTC?.loja.cpf_cnpj | CPFCNPJ}}
      </div>
    </div>
  </div>
  <p align="center" class="mt-2" *ngIf="senhaAdm">
    Senha do gerente <input type="password" [(ngModel)]="managerpwd">
  </p>
  <p align="center"><button type="button" (click)="assinar=true" class="btn btn-primary mt-5 mx-2" *ngIf="!assinado">
      Assinar</button>
    <button type="button" [disabled]="loading" (click)="submitAssinatura();" class="btn btn-primary mt-5"
      *ngIf="assinado"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      Confirmar assinatura</button> <button type="button" [disabled]="loading"
      (click)="assinar = true; assinado = false;" class="btn btn-primary mt-5 mx-2" *ngIf="assinado"><span
        *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      Assinar novamente</button> <button type="button" (click)="recibo = false; assinado = false"
      class="btn btn-primary mt-5" [disabled]="loading"><span *ngIf="loading"
        class="spinner-border spinner-border-sm mr-1"></span>Cancelar</button></p>
</div>
