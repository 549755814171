<div class="col-sm-12" align="center">
    <div class="col-sm-8">
        <h2 class="mb-3" align="center">Controle de equipamentos</h2>
        <form>
            <div class="form-group" align="left">
                <label for="codigoCliente">Arquivo Excel</label>
                <input type="file" class="form-control" id="arquivoLeitura"
                    placeholder="Escolha o arquivo para conferência">
            </div>
            <button type="submit" class="btn btn-primary">Carregar</button>
        </form>
    </div>

    <div class="col-sm-8 mt-5" align="left">
        <ul class="fa-ul">
            <li (click)="status1=!status1" [ngStyle]="status1 ? {'color': 'gray'} : {}"><span class="fa-li" [ngStyle]="status1 ? {'color': 'green'} : {'color': 'red'}"><i class="fas fa-circle"></i></span>81833 - Casaco infantil Hering 12
            </li>
            <li (click)="status2=!status2" [ngStyle]="status2 ? {'color': 'gray'} : {}"><span class="fa-li" [ngStyle]="status2 ? {'color': 'green'} : {'color': 'red'}"><i class="fas fa-circle"></i></span>84423 - Short infantil Bambi
            </li>
            <li (click)="status3=!status3" [ngStyle]="status3 ? {'color': 'gray'} : {}"><span class="fa-li" [ngStyle]="status3 ? {'color': 'green'} : {'color': 'red'}"><i class="fas fa-circle"></i></span>81344 - Sapato de camurça tamanho 32
            </li>
            <li (click)="status4=!status4" [ngStyle]="status4 ? {'color': 'gray'} : {}"><span class="fa-li" [ngStyle]="status4 ? {'color': 'green'} : {'color': 'red'}"><i class="fas fa-circle"></i></span>22761 - Roupa de inverno
            </li>
            <li (click)="status5=!status5" [ngStyle]="status5 ? {'color': 'gray'} : {}"><span class="fa-li" [ngStyle]="status5 ? {'color': 'green'} : {'color': 'red'}"><i class="fas fa-circle"></i></span>83745 - Cachecol Amarelo
            </li>
            <li (click)="status6=!status6" [ngStyle]="status6 ? {'color': 'gray'} : {}"><span class="fa-li" [ngStyle]="status6 ? {'color': 'green'} : {'color': 'red'}"><i class="fas fa-circle"></i></span>67434 - Par de meias tamanho G
            </li>
            <li (click)="status7=!status7" [ngStyle]="status7 ? {'color': 'gray'} : {}"><span class="fa-li" [ngStyle]="status7 ? {'color': 'green'} : {'color': 'red'}"><i class="fas fa-circle"></i></span>17263 - Cinto tamanho G
            </li>
            <li (click)="status8=!status8" [ngStyle]="status8 ? {'color': 'gray'} : {}"><span class="fa-li" [ngStyle]="status8 ? {'color': 'green'} : {'color': 'red'}"><i class="fas fa-circle"></i></span>71823 - Saia rodada para menina
            </li>
            <li (click)="status9=!status9" [ngStyle]="status9 ? {'color': 'gray'} : {}"><span class="fa-li" [ngStyle]="status9 ? {'color': 'green'} : {'color': 'red'}"><i class="fas fa-circle"></i></span>87182 - Cueca zorba infantil tamanho P
            </li>
            <li (click)="status10=!status10" [ngStyle]="status10 ? {'color': 'gray'} : {}"><span class="fa-li" [ngStyle]="status10 ? {'color': 'green'} : {'color': 'red'}"><i class="fas fa-circle"></i></span>14553 - Roupa de verão tamanho 12
            </li>
        </ul>
    </div>
</div>