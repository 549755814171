<div class="col-sm-12" align="center">
  <div class="col-sm-8">
    <h2 class="mb-3" align="center">Relatório de recibos</h2>
    <form [formGroup]="formForn" (ngSubmit)="submitForm()" class="form form-register" novalidate>
      <div class="row">
        <div class="col-sm-6" align="left">
          <label for="Data inicial
                    ">Data inicial</label>
          <input type="date" class="form-control" formControlName="dataini" placeholder="Entre com o código do cliente">
        </div>
        <div class="col-sm-6" align="left">
          <label for="Data final">Data final</label>
          <input type="date" class="form-control" formControlName="datafim">
        </div>
      </div>
      <div class="form-field mt-3" align="center">
        <button type="submit" class="btn btn-primary mx-2" [disabled]="!validForm()||loading"><span *ngIf="loading"
            class="spinner-border spinner-border-sm mr-1"></span>Pesquisar</button>
      </div>
    </form>
  </div>
  <div *ngIf="forn.length>0">
    <div>
      <table class="col-sm-12 mt-5">
        <thead class="table table-dark">
          <tr>
            <th scope="col-sm-5" colspan="2">
              Forma de Pagamento </th>
            <th scope="col-sm-2">
              Qtd. Saques </th>
            <th scope="col-sm-2">
              % de Part. </th>
            <th scope="col-sm-3">
              Valor </th>
          </tr>
        </thead>
        <tbody>
          <tr class="border-bottom">
            <td rowspan="3">
              Compra de Produtos </td>
            <td>
              Crédito </td>
            <td>
              {{+forn[0].qtd_credito}}
            </td>
            <td>
              {{percCredito.toFixed(0)}}%
            </td>
            <td>
              {{+forn[0].valor_credito | currency:'BRL'}}
            </td>
          </tr>
          <tr class="border-bottom">
            <td>
              Adiantamento </td>
            <td>
              {{+forn[0].qtd_antecipacao}}
            </td>
            <td>
              {{percAntecip.toFixed(0)}}%
            </td>
            <td>
              {{+forn[0].valor_antecipacao | currency:'BRL'}}
            </td>
          </tr>
          <tr style="background-color: lightgrey;">
            <td>
              Total </td>
            <td>
              {{+forn[0].qtd_credito+(+forn[0].qtd_antecipacao)}}
            </td>
            <td>
              {{percTotalCompra.toFixed(0)}}%
            </td>
            <td>
              {{+forn[0].valor_credito+(+forn[0].valor_antecipacao) | currency:'BRL'}}
            </td>
          </tr>
          <tr class="border-bottom">
            <td colspan="2">
              Saque em Dinheiro </td>
            <td>
              {{+forn[0].qtd_dinheiro}}
            </td>
            <td>
              {{percDinheiro.toFixed(0)}}%
            </td>
            <td>
              {{+forn[0].valor_dinheiro | currency:'BRL'}}
            </td>
          </tr>
          <tr class="border-bottom">
            <td colspan="2">
              Saque em Cheque </td>
            <td>
              {{+forn[0].qtd_cheque}}
            </td>
            <td>
              {{percCheque.toFixed(0)}}%
            </td>
            <td>
              {{+forn[0].valor_cheque | currency:'BRL'}}
            </td>
          </tr>
          <tr class="border-bottom">
            <td colspan="2">
              Transferência Bancária </td>
            <td>
              {{+forn[0].qtd_transferencia}}
            </td>
            <td>
              {{percTransfer.toFixed(0)}}%
            </td>
            <td>
              {{+forn[0].valor_transferencia | currency:'BRL'}}
            </td>
          </tr>
          <tr class="border-bottom">
            <td colspan="2">
              Pix </td>
            <td>
              {{+forn[0].qtd_pix}}
            </td>
            <td>
              {{percPix.toFixed(0)}}%
            </td>
            <td>
              {{+forn[0].valor_pix | currency:'BRL'}}
            </td>
          </tr>
          <tr class="border-bottom">
            <td colspan="2">
              Transferência Cartão PR </td>
            <td>
              {{0}}
            </td>
            <td>
              {{0}}%
            </td>
            <td>
              {{0.0 | currency:'BRL'}}
            </td>
          </tr>
          <tr style="background-color: #27828c; color: white">
            <td colspan="2">
              Total Geral
            </td>
            <td>
              {{+forn[0].qtd_dinheiro+(+forn[0].qtd_cheque)+(+forn[0].qtd_antecipacao)+(+forn[0].qtd_transferencia)+(+forn[0].qtd_pix)+(+forn[0].qtd_credito)}}
            </td>
            <td>
              {{(percTotalCompra+percDinheiro+percCheque+percTransfer+percPix).toFixed(0)}}%
            </td>
            <td>
              {{+forn[0].valor_dinheiro+(+forn[0].valor_cheque)+(+forn[0].valor_transferencia)+(+forn[0].valor_pix)+(+forn[0].valor_antecipacao)+(+forn[0].valor_credito) | currency:'BRL'}}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
</div>
