import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { User } from 'src/app/_models/user';
import { AuthenticationService, AlertService, RelatorioService } from 'src/app/_services';

@Component({
  selector: 'app-recibosaque',
  templateUrl: './recibosaque.component.html',
  styleUrls: ['./recibosaque.component.less']
})
export class RecibosaqueComponent implements OnInit {

  formForn = new FormGroup({
    dataini: new FormControl(this.currentDate(), []),
    datafim: new FormControl(this.currentDate(), []),
  });

  currentUserTC: User;

  loading: boolean = false;
  tabela: boolean = false;
  forn: any = [];

  percCompra: number = 0;
  percAntecip: number = 0;
  percTotalCompra: number = 0;
  percDinheiro: number = 0;
  percCheque: number = 0;
  percTransfer: number = 0;
  percPix: number = 0;
  percCredito: number = 0;

  constructor(
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private relatorioService: RelatorioService) {
    this.currentUserTC = this.authenticationService.currentUserTCValue;
  }

  currentDate() {
    const currentDate = new Date();
    return currentDate.toISOString().substring(0, 10);
  }

  validForm() {
    if ((this.formForn.get('dataini').value == '' || this.formForn.get('dataini').value == null)) {
      return false;
    }
    return true;
  }

  submitForm() {
    this.alertService.clear();
    this.loading = true;
    this.tabela = true;
    this.forn = [];
    this.relatorioService.relatorioSaque(this.currentUserTC.loja_id, this.formForn.value.dataini, this.formForn.value.datafim)
      .subscribe(d => {
        this.forn = d.data;
        //console.log(this.forn);
        //if(this.forn[0].qtd_compra)
          this.percCredito = (+this.forn[0].qtd_credito/(+this.forn[0].qtd_credito+(+this.forn[0].qtd_antecipacao)))*100;
          if(isNaN(this.percCredito)) this.percCredito = 0
        //if(this.forn[0].qtd_antecipacao)
          this.percAntecip = (+this.forn[0].qtd_antecipacao/(+this.forn[0].qtd_credito+(+this.forn[0].qtd_antecipacao)))*100;
          //if(isNaN(this.percAntecip)) this.percAntecip = 0
          if(isNaN(this.percAntecip)) this.percAntecip = 0
        //if(+this.forn[0].qtd_credito+(+this.forn[0].qtd_antecipacao) > 0)
          this.percTotalCompra = ((+this.forn[0].qtd_credito+(+this.forn[0].qtd_antecipacao))/(+this.forn[0].qtd_dinheiro+(+this.forn[0].qtd_cheque)+(+this.forn[0].qtd_transferencia)+(+this.forn[0].qtd_pix)+(+this.forn[0].qtd_credito)+(+this.forn[0].qtd_antecipacao)))*100;
          if(isNaN(this.percTotalCompra)) this.percTotalCompra = 0
        //if(this.forn[0].qtd_dinheiro)
          this.percDinheiro = (+this.forn[0].qtd_dinheiro/(+this.forn[0].qtd_dinheiro+(+this.forn[0].qtd_cheque)+(+this.forn[0].qtd_transferencia)+(+this.forn[0].qtd_pix)+(+this.forn[0].qtd_credito)+(+this.forn[0].qtd_antecipacao)))*100;
          if(isNaN(this.percDinheiro)) this.percDinheiro = 0
        //if(this.forn[0].qtd_cheque)
          this.percCheque = (+this.forn[0].qtd_cheque/(+this.forn[0].qtd_dinheiro+(+this.forn[0].qtd_cheque)+(+this.forn[0].qtd_transferencia)+(+this.forn[0].qtd_pix)+(+this.forn[0].qtd_credito)+(+this.forn[0].qtd_antecipacao)))*100;
          if(isNaN(this.percCheque)) this.percCheque = 0
        //if(this.forn[0].qtd_transferencia)
          this.percTransfer = (+this.forn[0].qtd_transferencia/(+this.forn[0].qtd_dinheiro+(+this.forn[0].qtd_cheque)+(+this.forn[0].qtd_transferencia)+(+this.forn[0].qtd_pix)+(+this.forn[0].qtd_credito)+(+this.forn[0].qtd_antecipacao)))*100;
          if(isNaN(this.percTransfer)) this.percTransfer = 0
          this.percPix = (+this.forn[0].pix/(+this.forn[0].qtd_transferencia/+this.forn[0].qtd_dinheiro+(+this.forn[0].qtd_cheque)+(+this.forn[0].qtd_transferencia)+(+this.forn[0].qtd_pix)+(+this.forn[0].qtd_credito)+(+this.forn[0].qtd_antecipacao)))*100;
          if(isNaN(this.percPix)) this.percPix = 0
        //if(this.forn[0].qtd_credito)
          //this.percCredito = (+this.forn[0].qtd_credito/(+this.forn[0].qtd_dinheiro+(+this.forn[0].qtd_cheque)+(+this.forn[0].qtd_transferencia)+(+this.forn[0].qtd_credito)+(+this.forn[0].qtd_antecipacao)))*100;
          //if(isNaN(this.percCredito)) this.percCredito = 0
      },
        (err) => this.alertService.error(err.error.message))
      .add(() => this.loading = false);
  }

  ngOnInit() {
  }

}
