<div class="container">
  <h2>Extrato de movimentações</h2>
  <div *ngIf="forn" class="row mb-3">
    <div class="col border-dark border-top border-left border-bottom rounded-left">
      <h4>Consignante</h4>
      <b>Código do contrato: </b>{{forn.maqplan_fornecedor_id}}<br />
      <b>Nome do consignante: </b>{{forn.fornecedor_razao_social}}<br />
      <b>CPF: </b>{{forn.fornecedor_cpf_cnpj | CPFCNPJ}}<br />
      <b>Notas para devolução:</b> {{forn.qtd_notas_devolucao}}
    </div>
    <div class="col border-dark border-top border-right border-bottom rounded-right">
      <h4>Saldo resumido</h4>
      <p><b>Saldo disponível: </b>{{forn.saldo_liberado | currency:'BRL'}}<br />
        <b>Saldo bloqueado: </b>{{forn.saldo_bloqueado | currency:'BRL'}}</p>
    </div>
  </div>
  <div *ngIf="forn.movimentos" class="col shadow-sm p-3 mb-3 mt-3 bg-white rounded">
    <h4>Extrato <a (click)="enviarExtrato()"><i class="far fa-envelope"></i></a></h4>
    <div class="row">
      <div class="col-sm-3" align="center"><b>Loja</b></div>
      <div class="col-sm-2" align="center"><b>Movimento</b></div>
      <div class="col-sm-5" align="center"><b>Descrição</b></div>
      <div class="col-sm-2" align="center"><b>Valor</b></div>
    </div>
    <div class="row td-row" *ngFor="let m of forn.movimentos">
      <div class="col-sm-3 border-dark border-right">{{forn.loja_nome_fantasia}}</div>
      <div class="col-sm-2 border-dark border-right">{{m.data_movimento_br}}</div>
      <div class="col-sm-5 border-dark border-right">{{m.movimento_motivo_name}}
        <ng-container *ngIf="m.recibo_id"> (<a
            href="https://prbko.lpbc.com.br/storage/upload/recibos/{{m.imagem_recibo}}" target="_blank">Recibo
            {{m.recibo_id}}</a>)
          <a (click)="enviarRecibo(m.recibo_id)"><i class="far fa-envelope"></i></a></ng-container>
      </div>
      <div class="col-sm-2" align="right" [ngStyle]="{'color': m.valor_extrato<0 ? 'red' : 'black'}">
        {{m.valor_extrato | currency:'BRL'}}</div>
    </div>
  </div>
  <div align="center"><button (click)="this._location.back();" [disabled]="!validForm()">{{botao}}</button></div>
</div>
