import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { AuthenticationService, AlertService, FornecedoresService, ReciboService } from 'src/app/_services';
import { User } from 'src/app/_models/user';

@Component({
  selector: 'app-saqueassinatura',
  templateUrl: './saqueassinatura.component.html',
  styleUrls: ['./saqueassinatura.component.less']
})
export class SaqueassinaturaComponent implements OnInit {
  currentUserTC: User;
  points = [];
  forn = [];
  signatureImage;

  formSaque = new FormGroup({
    valorD: new FormControl(),
    valorC: new FormControl(),
    valorT: new FormControl(),
    valorP: new FormControl(),
    check_num: new FormControl(),
    valorCT: new FormControl()
  });

  recibo: boolean = false;
  assinado: boolean = false;
  assinar: boolean = false;
  loading: boolean = false;
  senhaAdm: boolean = false;
  managerpwd: string;
  valorTotal: number;
  botao: string = 'Carregando...';
  id: string;
  pdf;

  showImage(data) {
    this.signatureImage = data;
    this.assinar = false;
    this.assinado = true;
  }

  constructor(
    private router: ActivatedRoute,
    private fornService: FornecedoresService,
    private reciboService: ReciboService,
    private authenticationService: AuthenticationService,
    private alertService: AlertService
  ) {
    this.currentUserTC = this.authenticationService.currentUserTCValue;
  }

  submitForm() {
    this.alertService.clear();
    if (this.fCurr(this.formSaque.value.valorD) + this.fCurr(this.formSaque.value.valorC) + this.fCurr(this.formSaque.value.valorT) + this.fCurr(this.formSaque.value.valorP) + this.fCurr(this.formSaque.value.valorCT) > this.fCurr(this.forn['saldo']['saldo_liberado'].toFixed(2))) {
      this.alertService.error('Valor não pode ser maior que o saldo disponível para saque.');
      return;
    }
    this.valorTotal = this.formSaque.value.valorD + this.formSaque.value.valorC + this.formSaque.value.valorT + this.formSaque.value.valorP + this.formSaque.value.valorCT;
    if (this.fCurr(this.formSaque.value.valorC) != 0.0 && this.formSaque.value.check_num == null) {
      this.alertService.error('Preencha o número do cheque.');
      return;
    }
    this.recibo = true;
  }

  validForm() {
    if (!this.forn['id'])
      return false
    else
      this.botao = 'Confirmar';
    if (this.fCurr(this.formSaque.get('valorD').value) + this.fCurr(this.formSaque.get('valorC').value) + this.fCurr(this.formSaque.get('valorT').value) + this.fCurr(this.formSaque.get('valorP').value) + this.fCurr(this.formSaque.get('valorCT').value) == 0.0) return false;
    if (this.formSaque.get('valorD').value == null && this.formSaque.get('valorC').value == null && this.formSaque.get('valorT').value == null && this.formSaque.get('valorP').value == null && this.formSaque.get('valorCT').value == null) return false;
    return true;
  }

  fCurr(input) {
    return input === null || isNaN(input) ? 0.0 : parseFloat(input);
  }

  submitAssinatura() {
    this.loading = true;
    this.alertService.clear();
    const formData = new FormData();
    formData.append('user', this.currentUserTC.id.toString());
    formData.append('store', this.currentUserTC.loja_id.toString());
    formData.append('provider', this.forn['id']);
    formData.append('check_num', this.formSaque.get('check_num').value);
    formData.append('money', this.formSaque.get('valorD').value);
    formData.append('bank_check', this.formSaque.get('valorC').value);
    formData.append('bank_transfer', this.formSaque.get('valorT').value);
    formData.append('pix', this.formSaque.get('valorP').value);
    formData.append('pr_card', this.formSaque.get('valorCT').value);
    formData.append('signature', this.signatureImage);
    if (this.managerpwd) formData.append('managerpwd', this.managerpwd);
    this.reciboService.reciboSaque(this.forn['id'], formData).subscribe(
      (res) => {
        //console.log(res);
        this.alertService.success(res.message);
        this.getFornecedor();
        this.pdf = res.msg.img_pdf
        this.loading = false;
        this.formSaque.reset();
      },
      (err) => {
        this.alertService.error(err.error.message || err.error.errors.value);
        if (err.error.errors && (this.formSaque.get('valorD').value > err.error.errors.limit)) this.senhaAdm = true;
        this.loading = false;
      }
    )
  }

  getFornecedor() {
    this.fornService.getDadosFornecedor(this.currentUserTC.loja_id, this.id)
      .subscribe(f => {
        this.forn = f.data;
        //console.log(this.forn);
        if (f.data.nf_devolucao) this.alertService.success('Existem notas para devolução (' + f.data.nf_devolucao.qtd_notas_devolucao + ')');
      },
        (err) => this.alertService.error(err.error.message));
  }

  ngOnInit() {
    this.id = this.router.snapshot.params.id;
    this.getFornecedor();
  }

}
