import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ProfileComponent } from './profile/profile.component';
import { ContratopendenteComponent } from './contrato/contratopendente/contratopendente.component';
import { AssinaturaComponent } from './assinatura/assinatura.component';
import { DocumentoComponent } from './contrato/anexos/documento.component';
import { ExtratodetalheComponent } from './recibo/extratodetalhe/extratodetalhe.component';
import { CompraassinaturaComponent } from './recibo/compraassinatura/compraassinatura.component';
import { SaqueassinaturaComponent } from './recibo/saqueassinatura/saqueassinatura.component';
import { DevolucaoComponent } from './recibo/devolucao/devolucao.component'
import { PesquisafornComponent } from './pesquisaforn/pesquisaforn.component';
import { RecibosaqueComponent } from './relatorios/recibosaque/recibosaque.component';
import { AuthGuard } from './_helpers/auth.guard';
import { RfidComponent } from './rfid/rfid.component';
import { LgpdComponent } from './lgpd/lgpd.component';

const routes: Routes = [
    { path: '', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
    { path: 'pesquisaforn/:rota', component: PesquisafornComponent, canActivate: [AuthGuard] },
    { path: 'contratopendente/:id', component: ContratopendenteComponent, canActivate: [AuthGuard]  },
    { path: 'assinatura', component: AssinaturaComponent, canActivate: [AuthGuard]  },
    { path: 'assinatura/:contrato', component: AssinaturaComponent, canActivate: [AuthGuard]  },
    { path: 'anexo', component: DocumentoComponent, canActivate: [AuthGuard]  },
    { path: 'anexo/:contrato', component: DocumentoComponent, canActivate: [AuthGuard]  },
    { path: 'extratodetalhe/:id', component: ExtratodetalheComponent, canActivate: [AuthGuard]  },
    { path: 'devolucao/:id/:idNota', component: DevolucaoComponent, canActivate: [AuthGuard]  },
    { path: 'compraassinatura/:id', component: CompraassinaturaComponent, canActivate: [AuthGuard]  },
    { path: 'saqueassinatura/:id', component: SaqueassinaturaComponent, canActivate: [AuthGuard]  },
    { path: 'relatoriorecibosaque', component: RecibosaqueComponent, canActivate: [AuthGuard]  },
    { path: 'rfid', component: RfidComponent, canActivate: [AuthGuard]  },
    { path: 'lgpd', component: LgpdComponent, canActivate: [AuthGuard]  },

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const appRoutingModule = RouterModule.forRoot(routes);
