import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { Contrato } from '../_models/contrato'
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-assinatura',
  templateUrl: './assinatura.component.html',
  styleUrls: ['./assinatura.component.less']
})
export class AssinaturaComponent implements OnInit {
  idContrato: string;
  points = [];
  signatureImage;
  myTemplate;
  assinado = false;
  salvar = false;
  imagem1;
  imagem2;

  showImage(data) {
    this.signatureImage = data;
    localStorage.setItem(this.idContrato, JSON.stringify(this.signatureImage));
    this.assinado = true;
    this.salvar = true;
  }

  cancelarAssinatura() {
    this.signatureImage = null;
    this.assinado = false;
    this.salvar = false;
  }

  constructor(
    private router: ActivatedRoute,
    private contrato: Contrato,
    protected sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    //localStorage.removeItem('Dong Silver');
    this.idContrato = this.router.snapshot.params.contrato;
    //console.log(this.idContrato);
    if(this.idContrato) {
        let imgAssinaturaStorage = JSON.parse(localStorage.getItem(this.idContrato));
        if(imgAssinaturaStorage) {
          this.assinado = true;
          this.signatureImage = imgAssinaturaStorage;
          if(localStorage.getItem(this.idContrato + 'imagem1') != 'undefined')
            this.imagem1 = JSON.parse(localStorage.getItem(this.idContrato + 'imagem1'));
          if(localStorage.getItem(this.idContrato + 'imagem2') != 'undefined')
            this.imagem2 = JSON.parse(localStorage.getItem(this.idContrato + 'imagem2'));
        }
    }

    /* if (this.idContrato) {
      this.http.get('../../assets/Contratos/' + this.idContrato + '/contrato.html', { responseType: 'text' }).subscribe(data => {
        this.myTemplate = data;
      })
    } */
    this.myTemplate = this.sanitizer.bypassSecurityTrustHtml(this.contrato.textoContrato);

  }

  ngAfterViewInit() {
    document.querySelector('#assinatura').appendChild(document.querySelector('#signaturePad'));
  }
}
