import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/_models/user';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService, AlertService, ReciboService } from 'src/app/_services';

@Component({
  selector: 'app-devolucao',
  templateUrl: './devolucao.component.html',
  styleUrls: ['./devolucao.component.less']
})
export class DevolucaoComponent implements OnInit {

  currentUserTC: User;
  points = [];
  dev: any = [];
  signatureImage;

  recibo: boolean = false;
  assinado: boolean = false;
  assinar: boolean = false;
  loading: boolean = true;
  botao: string = 'Carregando...';

  pdf;
  id: string;
  idNota: string[];

  showImage(data) {
    this.signatureImage = data;
    this.assinar = false;
    this.assinado = true;
  }

  constructor(
    private router: ActivatedRoute,
    private reciboService: ReciboService,
    private authenticationService: AuthenticationService,
    private alertService: AlertService
  ) {
    this.currentUserTC = this.authenticationService.currentUserTCValue;
    //console.log(this.currentUserTC);
  }

  submitAssinatura() {
    this.loading = true;
    this.alertService.clear();
    const formData = new FormData();
    formData.append('user', this.currentUserTC.id.toString());
    formData.append('provider', this.dev[0].fornecedor_id);
    formData.append('invoice', this.idNota.join());
    formData.append('signature', this.signatureImage);
    this.reciboService.reciboDevolucaoSalvar(this.currentUserTC.loja_id, formData).subscribe(
      (res) => {
        //console.log(res);
        this.alertService.success(res.message);
        this.pdf = "Yes"; //res.msg.img_pdf;
        this.loading = false;
      },
      (err) => {
        //console.log(err);
        this.alertService.error(err.error.message);
        this.loading = false;
      })
  }

  getDevolucao() {
    this.reciboService.reciboDevolucao(this.currentUserTC.loja_id, this.id)
    //this.reciboService.reciboDevolucao(2, '309')
      .subscribe(d => {
        this.dev = d.data.filter(x => this.idNota.includes(x.nf_num.toString()));
        this.botao = 'Assinar';
        this.loading = false;
      },
        (err) => {
          this.alertService.error(err.error.message);
          this.loading = false;
        });
  }

  ngOnInit() {
    this.id = this.router.snapshot.params.id;
    this.idNota = this.router.snapshot.params.idNota.split(',');
    this.getDevolucao();
  }

}
