import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

import { User } from '../_models/user';
import { AuthenticationService } from '../_services';

@Component({ templateUrl: 'home.component.html' })
export class HomeComponent implements OnInit {
    currentUserTC: User;
    users = [];

    constructor(
        private authenticationService: AuthenticationService
    ) {
        this.currentUserTC = this.authenticationService.currentUserTCValue;
    }

    ngOnInit() {
        //this.loadAllUsers();
    }

/*    deleteUser(id: number) {
        this.userService.delete(id)
            .pipe(first())
            .subscribe(() => this.loadAllUsers());
    }

     private loadAllUsers() {
        this.userService.getAll()
            .pipe(first())
            .subscribe(users => this.users = users);
    } */
}
