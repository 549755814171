import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FornecedoresService {
  constructor(private http: HttpClient) { }

  getFornecedor(id_loja: number, matricula?: string, nome?: string, cpf?: string): Observable<any> {
    const params = new HttpParams().set('matricula', matricula || '').set('nome', nome || '').set('cpf', cpf || '');
    return this.http.get(`${environment.apiUrl}/v1/recibos/fornecedores/${id_loja}`, { params });
  }

  getDadosFornecedor(id_loja: number, matricula?: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/v1/recibos/fornecedores/${id_loja}/view/${matricula}`);
  }

/*   getFornecedores(id_loja: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/fornecedores/${id_loja}`);
  } */

  sync(id_loja: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/fornecedores/${id_loja}/sync`);
  }

}
