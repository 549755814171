<div class="col-sm-12" align="center">
  <div class="col-sm-8">
    <h2 class="mb-3" align="center">{{titulo}}</h2>
    <form [formGroup]="formForn" (ngSubmit)="submitForm()" class="form form-register" novalidate>
      <div class="form-field" align="left">
        <label for="codigo
                    ">Código do cliente</label>
        <input type="tel" class="form-control" formControlName="codigoCliente"
          placeholder="Entre com o código do cliente">
      </div>
      <div class="form-field" align="left">
        <label for="nome
                    ">Nome</label>
        <input type="text" class="form-control" formControlName="nomeCliente" placeholder="Entre com o nome do cliente">
      </div>
      <div class="form-field" align="left">
        <label for="CPF">CPF</label>
        <input type="tel" class="form-control" formControlName="cpf"
          placeholder="Entre com o CPF">
      </div>
      <div class="form-field mt-3" align="center">
        <!-- <button type="button" (click)="sync()" class="btn btn-primary" [disabled]="loading"
          *ngIf="rota=='contratopendente'"><span *ngIf="loading"
            class="spinner-border spinner-border-sm mr-1"></span>Sincronizar</button> -->
        <button type="submit" class="btn btn-primary mx-2" [disabled]="!validForm()||loading"><span *ngIf="loading"
            class="spinner-border spinner-border-sm mr-1"></span>Pesquisar</button>
        <button type="button" class="btn btn-primary mx-2" [disabled]="!verificaItemSelecionado()||loading" (click)="assinarItens()" *ngIf="dev.length>0"><span *ngIf="loading"
            class="spinner-border spinner-border-sm mr-1"></span>Devolver notas selecionadas ({{verificaTotalSelecionado()}})</button>
      </div>
    </form>
  </div>
  <div *ngIf="cont.length>0||forn.length>0||dev.length>0" class="mt-5">
    <h3 *ngIf="dev.length>0">Clique nas notas para selecionar</h3>
    <table class="col-sm-8 table table-bordered table-striped">
      <thead class="table table-dark ">
        <tr>
          <th scope="col-sm-3">Loja</th>
          <th scope="col-sm-3" *ngIf="forn.length>0||cont.length>0">Cliente</th>
          <th scope="col-sm-2" *ngIf="dev.length>0">Fornecedor</th>
          <th scope="col-sm-2" *ngIf="dev.length>0">Local</th>
          <th scope="col-sm-2" *ngIf="dev.length>0" (click)="selecionarTodas()">Nota</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let f of forn">
          <th scope="row" class="mx-2" valign="top">{{f.loja.loja_nome_fantasia}}</th>
          <td class="mx-2" valign="top"><a *ngIf="rota!='extratodetalhe'"
              routerLink="/{{rota}}/{{f.id}}">{{f.fornecedor_razao_social}}</a>
            <a *ngIf="rota=='extratodetalhe'"
              routerLink="/{{rota}}/{{f.fornecedor_contrato}}">{{f.fornecedor_razao_social}}</a>
          </td>
        </tr>
        <tr *ngFor="let c of cont">
          <th scope="row" class="mx-2" valign="top">{{c.loja.loja_nome_fantasia}}</th>
          <td class="mx-2" valign="top">
            <a routerLink="/{{rota}}/{{c.id}}">{{c.fornecedor_razao_social}}</a>
          </td>
        </tr>
        <tr *ngFor="let d of dev" [class.selecionado]="d.itemSelecionado" (click)="d.itemSelecionado = !d.itemSelecionado;">
          <th scope="row" class="mx-2" valign="top">{{d.loja_nome_fantasia}}</th>
          <td class="mx-2" valign="top">
            {{d.fornecedor_razao_social}}
          </td>
          <td class="mx-2" valign="top">
            {{d.local || '-'}}
          </td>
          <td class="mx-2" valign="top">
            <!-- <a routerLink="/{{rota}}/{{d.maqplan_fornecedor_id}}/{{d.nf_num}}">Nota {{d.nf_num}}</a> -->
            Nota {{d.nf_num}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
