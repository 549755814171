<div class="col-6">
    <h2>Login</h2>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label for="username">Usuário</label>
            <input type="tel" formControlName="username" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                <div *ngIf="f.username.errors.required">Usuário é obrigatório</div>
            </div>
        </div>
        <div class="form-group">
            <label for="password">Senha</label>
            <input type="password" formControlName="password" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Senha é obrigatória</div>
            </div>
        </div>
        <div class="form-group">
            <button [disabled]="loading" class="btn btn-primary">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Login
            </button>
            <!-- <a routerLink="/register" class="btn btn-link">Registrar</a> -->
        </div>
    </form>
</div>
