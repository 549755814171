import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import packageInfo from 'package.json';

import { AuthenticationService } from './_services/authentication.service';
import { User } from './_models/user';

import './_content/app.less';

@Component({ selector: 'app', templateUrl: 'app.component.html' })
export class AppComponent {
  currentUserTC: User;
  ano = (new Date()).getFullYear();
  version = packageInfo.version;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    public swUpdate: SwUpdate
  ) {
    this.authenticationService.currentUserTC.subscribe(x => this.currentUserTC = x);
    swUpdate.available.subscribe(event => {
      console.log('A versão atual é ', event.current);
      console.log('A versão disponível é', event.available);
    });
    swUpdate.activated.subscribe(event => {
      console.log('A versão antiga é ', event.previous);
      console.log('A nova versão é ', event.current);
    });

    swUpdate.available.subscribe(event => {
      swUpdate.activateUpdate().then(() => this.updateApp());
    });
  }

  updateApp() {
    if (confirm('Nova versão disponível. Deseja atualizar?')) {
      document.location.reload();
      console.log('=== O app está atualizando');
    } else {
      console.log('=== O app NÃO está atualizando');
    }
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  ngOnInit() {

  }
}
