import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'CPFCNPJ'
})
export class CPFCNPJ implements PipeTransform {

  transform(value: string): string {
    if(!value) return;
    switch(value.length) {
    case 14:
        return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,'\$1.\$2.\$3\/\$4\-\$5');
    case 11:
        return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,'\$1.\$2.\$3\-\$4');
    default:
        return value;
    }
  }

}
