<div id="htmlTemplate" class="row" [innerHTML]="myTemplate"></div>
<div id="signaturePad" class="row" >
  <ng-signature-pad doneButtonText="Salvar" clearButtonText="Limpar" width="450" format="base64" (done)="showImage($event)" *ngIf="!assinado">
  </ng-signature-pad>
  <img [src]="signatureImage" *ngIf="signatureImage" />
</div>

<div class="row mt-5" *ngIf="imagem1||imagem2">
  <div class="col-6 text-center">
    <b>Identidade</b><br />
    <img [src]="imagem1" *ngIf="imagem1" width="300">
  </div>
  <div class="col-6 text-center">
    <b>Comprovante de residência</b><br />
    <img [src]="imagem2" *ngIf="imagem2" width="300">
  </div>
</div>

<div class="row justify-content-center" id="salvarContrato" *ngIf="salvar">
  <button type="button" class="btn btn-primary" [routerLink]="['/anexo', idContrato]">Confirmar assinatura</button>
  <button type="button" class="btn btn-primary" (click)="cancelarAssinatura()">Cancelar</button>
</div>
