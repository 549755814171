import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rfid',
  templateUrl: './rfid.component.html',
  styleUrls: ['./rfid.component.less']
})
export class RfidComponent implements OnInit {

  status1;
  status2;
  status3;
  status4;
  status5;
  status6;
  status7;
  status8;
  status9;
  status10;

  constructor() { }

  ngOnInit() {

  }

}
